import { USER_RELOAD } from '../actions/actionTypes/trainer';
import {
    AUTH_WITH_PASSWORD_REQUIRED,
    DO_LOGIN_FAILED,
    DO_LOGIN_REQUEST,
    DO_LOGIN_SUCCESS,
    GET_USER_PROFILE_FAIL,
    GET_USER_PROFILE_SUCCESS,
    HIDE_SAVED,
    LOGOUT,
    SET_LOCALE_FAIL,
    SET_LOCALE_REQUEST,
    SET_LOCALE_SUCCESS,
    SET_USER_SCORE_FAILED,
    SET_USER_SCORES_SUCCESS,
    TOKEN_REFRESH_SUCCESS,
    UPDATE_NICK_NAME_FAIL,
    UPDATE_NICK_NAME_REQUEST,
    UPDATE_NICK_NAME_SUCCESS
} from '../actions/actionTypes/user';
import { selectUserFromToken } from '../../utils/jwt';
import { getValidLocale } from '../../utils/locale';
import { reload } from '../../background/store/actions/common';
import { loginWithCompanyAndPassword } from '../../background/store/actions/auth';
import {
    updateUserLocale,
    updateUserUsername
} from '../../background/store/actions/user';
import { saveLoginInfo } from '../../utils/login';

// type Action = {
//     type: string,
//     payload: Object,
//     error: Object
// };
//
// type State = {
//     token: string,
//     locale: string,
//     emailVerified: boolean,
//     userName: string | null,
//     personalUsername: string | null,
//     companyName: string | null,
//     email: string | null,
//     userScore: Object | null,
//     role: string | null,
//     agencyId: number | null,
//     locationId: number | null,
//     companyId: number | null,
//     userId: number | null,
//     telephonyId: string | '',
//     authWithPasswordRequired: boolean,
//     authWithPasswordError: string,
//     authWithPasswordPending: boolean,
//     pending: boolean,
//     saved: boolean,
//     errorCode: number | null,
//     initialized: boolean
// };

export const initialState = {
    token: '',
    locale: getValidLocale(navigator.language),
    emailVerified: false,
    userName: null,
    personalUsername: null,
    companyName: null,
    email: null,
    userScore: null,
    userScoreError: null,
    role: null,
    telephonyId: '',
    agencyId: null,
    locationId: null,
    companyId: null,
    userId: null,
    authWithPasswordRequired: false,
    authWithPasswordPending: false,
    authWithPasswordError: '',
    pending: true,
    saved: false,
    errorCode: null,
    initialized: false
};

// eslint-disable-next-line default-param-last
const user = (state = initialState, action) => {
    const { type, payload, error } = action;

    switch (type) {
        case HIDE_SAVED: {
            return {
                ...state,
                saved: false
            };
        }
        case SET_LOCALE_REQUEST: {
            window.dispatch2background(updateUserLocale(payload.locale));

            return { ...state, locale: payload.locale, saved: false, pending: true };
        }
        case SET_LOCALE_SUCCESS: {
            window.dispatch2background(
                reload({ isImmediate: true, reason: 'REFRESH_CONFIG' })
            );

            return {
                ...state,
                locale: payload.languageCode,
                saved: true,
                pending: false
            };
        }
        case SET_LOCALE_FAIL: {
            return { ...state, error: payload.error, saved: false, pending: false };
        }
        case GET_USER_PROFILE_FAIL: {
            return initialState;
        }
        case AUTH_WITH_PASSWORD_REQUIRED: {
            return {
                ...state,
                token: '',
                authWithPasswordRequired: true
            };
        }
        case DO_LOGIN_REQUEST: {
            const { locationName, username, password } = payload;
            window.dispatch2background(
                loginWithCompanyAndPassword(locationName, username, password)
            );

            saveLoginInfo(locationName, username, password);

            return {
                ...state,
                authWithPasswordError: '',
                authWithPasswordPending: true
            };
        }
        case TOKEN_REFRESH_SUCCESS: {
            return {
                ...state,
                token: payload.token
            };
        }
        case DO_LOGIN_SUCCESS: {
            const userData = selectUserFromToken(payload.token);
            return {
                ...state,
                token: payload.token,
                role: userData.role,
                agencyId: userData.agencyId,
                locationId: userData.locationId,
                companyId: userData.companyId,
                isVodafone: userData.role === 'vf_agent',
                userId: userData.userId,
                authWithPasswordRequired: payload.isPasswordAuth
            };
        }
        case DO_LOGIN_FAILED: {
            let message = 'login.UnknownError';
            switch (payload.status) {
                case 0: // no network
                    message = 'login.NoNetwork';
                    break;
                case 401: // wrong credentials
                    message = 'login.InvalidCredentials';
                    break;
                default: // skip
            }

            return {
                ...state,
                token: '',
                authWithPasswordError: message,
                authWithPasswordPending: false
            };
        }
        case USER_RELOAD: {
            window.dispatch2background(
                reload({
                    isImmediate: true,
                    reason: 'REQUEST_DEVICE_LIST'
                })
            );

            return state;
        }
        case GET_USER_PROFILE_SUCCESS: {
            return {
                ...state,
                id: payload.id,
                emailVerified: payload.emailVerified,
                telephonyId: payload.telephonyId,
                locale: payload.languageCode,
                personalUsername: payload.personalUsername,
                userName: payload.companyUsername,
                companyName: payload.company.name,
                email: payload.email,
                pending: false,
                initialized: true
            };
        }
        case SET_USER_SCORES_SUCCESS: {
            return {
                ...state,
                userScore: payload
            };
        }
        case SET_USER_SCORE_FAILED: {
            return {
                ...state,
                userScoreError: error
            };
        }

        /*
        UPDATE_NICK_NAME
         */

        case UPDATE_NICK_NAME_REQUEST: {
            window.dispatch2background(updateUserUsername(payload.nickName));

            return {
                ...state,
                personalUsername: payload.nickName,
                pending: true,
                saved: false
            };
        }

        case UPDATE_NICK_NAME_SUCCESS: {
            return {
                ...state,
                pending: false,
                saved: true,
                errorCode: null
            };
        }

        case UPDATE_NICK_NAME_FAIL: {
            return {
                ...state,
                pending: false,
                enabled: true,
                saved: false,
                errorCode: payload.errorCode
            };
        }

        case LOGOUT: {
            window.dispatch2background(
                reload({
                    isImmediate: true,
                    reason: 'LOGOUT',
                    logout: true
                })
            );

            return state;
        }

        default: {
            return state;
        }
    }
};

export default user;
