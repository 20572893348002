import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { Wrapper, List, Item, ItemContainer } from './styled';
import { Icon, ClickOutside } from '../../common';

import { colors, routes, config } from '../../../utils';
import { DEFAULT_SNOOZE_TIMER } from '../../../utils/snooze';
import { IS_LEGACY_LISTENER_MODE } from '../../../config/electron';
import { CALL_SIMULATOR_PAGE } from '../../../utils/routes';

const { TRAINER_PAGE, SETTINGS_PAGE } = routes;

class Menu extends Component {
    static propTypes = {
        localization: PropTypes.object.isRequired,
        disabled: PropTypes.bool.isRequired,
        displaySnoozingControls: PropTypes.bool.isRequired,
        isTrainerActive: PropTypes.bool.isRequired,
        resumeTrainer: PropTypes.func.isRequired,
        snoozeTrainer: PropTypes.func.isRequired,
        doLogout: PropTypes.func,
        setInfinitySnooze: PropTypes.func.isRequired,
        isVodafone: PropTypes.bool.isRequired,
        showDashboardLink: PropTypes.bool.isRequired,
        authToken: PropTypes.string,
        authWithPasswordRequired: PropTypes.bool.isRequired,
        enableCallSimulator: PropTypes.bool.isRequired
    };

    static defaultProps = {
        doLogout: undefined,
        authToken: undefined
    };

    state = {
        open: false
    };

    _toggleOpen = () => {
        if (this.props.disabled) {
            return;
        }
        this.setState(prevState => ({
            open: !prevState.open
        }));
    };

    _handleOuterClick = () => {
        this.setState({
            open: false
        });
    };

    _handleSnoozeClick = () => {
        const { resumeTrainer, isTrainerActive, snoozeTrainer } = this.props;

        if (isTrainerActive) {
            snoozeTrainer(DEFAULT_SNOOZE_TIMER);
        } else {
            resumeTrainer();
        }
    };

    _openDashboard = () => {
        const { isVodafone, authToken } = this.props;
        const dashboardUrl = config.getDashboardLink(process.env.NODE_ENV, isVodafone);
        const params = encodeURIComponent(authToken);
        window.open(
            `${dashboardUrl}/login?token=${params}`,
            !IS_LEGACY_LISTENER_MODE ? '_blank' : '_self'
        );
    };

    _deactivate = () => {
        this.props.setInfinitySnooze();
    };

    _logout = () => {
        this.props.doLogout && this.props.doLogout();
    };

    render() {
        const { open } = this.state;
        const {
            localization,
            disabled,
            displaySnoozingControls,
            isTrainerActive,
            showDashboardLink,
            authWithPasswordRequired,
            enableCallSimulator
        } = this.props;

        return (
            <ClickOutside onClickOutside={this._handleOuterClick}>
                <Wrapper $open={open} $disabled={disabled} onClick={this._toggleOpen}>
                    <Icon
                        name="Menu"
                        color={open ? colors.blue.palatinate_blue : colors.black.default}
                    />
                    <List $visible={open}>
                        <NavLink to={TRAINER_PAGE}>
                            <Item>{localization.getText('header.menu.home')}</Item>
                        </NavLink>
                        {showDashboardLink && (
                            <Item onClick={this._openDashboard}>
                                <ItemContainer>
                                    <span>
                                        {localization.getText('header.menu.dashboard')}
                                    </span>
                                    <Icon name="Launch" />
                                </ItemContainer>
                            </Item>
                        )}
                        {displaySnoozingControls && (
                            <Item onClick={this._handleSnoozeClick}>
                                <ItemContainer>
                                    <span>
                                        {isTrainerActive
                                            ? localization.getText('header.menu.snooze')
                                            : localization.getText('header.menu.enable')}
                                    </span>
                                    <Icon name="Mic" />
                                </ItemContainer>
                            </Item>
                        )}
                        {displaySnoozingControls && (
                            <Item onClick={this._deactivate}>
                                {localization.getText('trainer.inactive.deactivate')}
                            </Item>
                        )}
                        <NavLink to={SETTINGS_PAGE}>
                            <Item>{localization.getText('header.menu.settings')}</Item>
                        </NavLink>
                        {enableCallSimulator && (
                            <NavLink to={CALL_SIMULATOR_PAGE}>
                                <Item>
                                    {localization.getText('header.menu.call_simulator')}
                                </Item>
                            </NavLink>
                        )}
                        {authWithPasswordRequired && (
                            <Item onClick={this._logout}>
                                <ItemContainer>
                                    {localization.getText('header.menu.logout')}
                                </ItemContainer>
                            </Item>
                        )}
                    </List>
                </Wrapper>
            </ClickOutside>
        );
    }
}

export default Menu;
