import {
    SET_TIMELINE_TRANSCRIPT,
    SET_TRANSCRIPT_COPY_BUTTON_AVAILABILITY,
    TOGGLE_TRANSCRIPT
} from '../actions/actionTypes/trainer';

// type Action = {
//     type: string,
//     payload: Object
// };
//
// type State = {
//     timelineTranscripts: Array<Object>,
//     isVisible: boolean,
//     copyEnabled: boolean
// };

export const initialState = {
    timelineTranscripts: [],
    copyEnabled: true,
    isVisible: false
};

// eslint-disable-next-line default-param-last
const transcriptReducer = (state = initialState, action) => {
    const { type, payload = {} } = action;
    switch (type) {
        case SET_TIMELINE_TRANSCRIPT: {
            return { ...state, timelineTranscripts: payload.timelineTranscripts };
        }
        case SET_TRANSCRIPT_COPY_BUTTON_AVAILABILITY: {
            return {
                ...state,
                copyEnabled: payload.copyEnabled
            };
        }
        case TOGGLE_TRANSCRIPT: {
            return { ...state, isVisible: payload.isVisible };
        }
        default: {
            return state;
        }
    }
};

export default transcriptReducer;
