export default {
    green: {
        glow: '#4caf50',
        aquamarine: '#3EDDA0',
        light: '#C8EAE4',
        magic_mint: '#A4EACF',
        flashygreen: '#3cd69b',
        lemon_grass: '#969D8C',
        envy: '#88AC91',
        padua: '#7DBB98',
        medium_aquamarine: '#75CA9E',
        shamrock: '#3bd69b',
        magic_mint2: '#B1EFD7'
    },
    blue: {
        dark_blue: '#002665',
        madison: '#062761',
        catskill: '#EFF3F8',
        i2x: '#5272EF',
        palatinate_blue: '#3C47E2',
        chadflores: '#EEF3F9',
        hawkes_blue: '#D6E4FE',
        cloudy_blue: '#B8C0CE',
        bay_of_many: '#1C4080',
        rock_blue: '#8D9DB9',
        link_water: '#CDD3DF',
        royal_blue: '#4356DE',
        cadet: '#A6B1C6',
        saphire: '#0A2D64',
        biscay: '#183062',
        dark_washed: '#4550e6',
        persian_blue: '#1c39bb',
        blue_icons: '#8D94F2'
    },
    red: {
        copper: '#EF8365',
        sunglo: '#EF6565',
        cupid: '#F4B4BF',
        pale: '#F9D9DE',
        radical_red: '#FF4663',
        light_carmine_pink: '#FF506E',
        froly: '#DA6976',
        old_rose: '#C7747B',
        brandy_rose: '#B68280',
        zorba: '#A59086'
    },
    white: {
        basic: '#FFFFFF',
        smoke: '#F6F6F5',
        white_smoke: '#F6F5F5',
        albaster: '#FBFBFB',
        lilac: '#E8E8E8',
        desert_storm: '#F8F8F8',
        lyly: '#EAEAEA'
    },
    gray: {
        basic: '#808080',
        monsoon: '#545454',
        jumbo: '#888888',
        dark: '#222222',
        mischka: '#CED3DE',
        dove_gray: '#757575',
        light: '#D1D4D6',
        silver_sand: '#BBBBBB',
        wild_sand: '#F5F4F3',
        rock_blue: '#97A3B9',
        solitude: '#E9ECEF',
        charcoal: '#4A4A4A',
        athens: '#EDEFF2',
        broken_white: '#f2f2f2',
        silver_chalice: '#adadad',
        iron: '#d8d8d8',
        gray: '#EAEBEB'
    },
    yellow: {
        golden_tanoi: '#FEC75D',
        sandy_beach: '#FFEBC7',
        makara: '#695F4D',
        neon_carrot: '#FFA047',
        rajah: '#F4AD61'
    },
    black: {
        default: '#000000',
        nero: '#222222'
    }
};
