import styled from 'styled-components';

import { colors } from '../../../utils';

const TranscriptItem = styled.div`
    &.botResults {
        align-self: flex-start;
        background-color: ${colors.gray.light};
        color: ${colors.black.default};
        border-radius: 0 8px 8px 8px;
    }

    &.agentResults {
        align-self: flex-end;
        background-color: ${colors.blue.palatinate_blue};
        color: ${colors.white.basic};
        border-radius: 8px 8px 0 8px;
    }
`;

export default TranscriptItem;
