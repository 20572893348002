import { get, isString } from 'lodash';
import axios from 'axios';
import { types as commonTypes } from '../actions/common';
import { objectToCamelCase, objectToSnakeCase, safeStringify } from '../../utils';
import { captureException } from '../../../tracker/raven';
import { Network } from '../../errors';
import { BASE_URL } from '../../../utils/config';
import {
    ERR_INVALID_AUTH_SCHEME,
    ERR_REFRESH_FAILED,
    ERR_LOGIN_FAILED,
    REQUESTS
} from '../../constants';

const { ApiError } = Network;

export const sendRequest = (
    { method, route, apiUrl = BASE_URL } = {},
    jwt = null,
    data = null,
    queryParams = '',
    keepKeysCase = false
) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
        const configuration = {
            method,
            headers: { 'Content-Type': 'application/json' }
        };

        if (data) {
            configuration.data = !keepKeysCase ? objectToSnakeCase(data) : data;
        }

        if (queryParams) {
            configuration.params = queryParams;
        }

        if (jwt) {
            configuration.headers.Authorization = `Bearer ${jwt}`;
        }

        try {
            const url = `${apiUrl}${route}`;
            const response = await axios(url, configuration);
            resolve(response.data);
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code that falls out of the range of 2xx
                const message = error.response.data.error || error.response.data;

                reject(
                    new ApiError({
                        method,
                        route,
                        status: error.response.status,
                        message: isString(message) ? message : safeStringify(message)
                    })
                );
            } else if (error.request) {
                // The request was made but no response was received `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                reject(
                    new ApiError({
                        method,
                        route,
                        status: 0,
                        message: `status - none, body - no response received`
                    })
                );
            } else {
                reject(error);
            }
        }
    });
};

const handleRequest = async (store, next, action) => {
    try {
        const token = get(store.getState(), 'auth.token');
        const response = await sendRequest(
            action.request,
            token,
            get(action, 'payload.body'),
            get(action, 'payload.queryParams'),
            action.keepKeysCase
        );
        if (action.onSuccess) {
            const nextAction = action.onSuccess(objectToCamelCase(response));
            if (nextAction) {
                next(nextAction);
            }
        }
    } catch (error) {
        if (
            action.request.route !== REQUESTS.SEND_LOG.route &&
            error.message !== ERR_INVALID_AUTH_SCHEME &&
            error.message !== ERR_REFRESH_FAILED &&
            error.message !== ERR_LOGIN_FAILED
        ) {
            captureException(error);
        }

        const { status } = get(error, 'data', {});
        if (status && [401, 403].includes(status)) {
            next({ type: commonTypes.UNAUTHORIZED_REQUEST, error });
        }

        if (action.onError) {
            const nextAction = action.onError(error);

            if (nextAction) {
                next(nextAction);
            }
        }
    }
};

export default store => next => action => {
    if (action.type === commonTypes.NETWORK_REQUEST) {
        return handleRequest(store, next, action);
    }

    return next(action);
};
