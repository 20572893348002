import { safeStringify } from '../background/utils';

export const captureException = error => {
    console.error(error);

    if (window.dispatchLog) window.dispatchLog(error);
};

export const captureWarnMessage = (message = '', options = null) => {
    if (options) {
        console.warn(message, safeStringify(options));
    } else {
        console.warn(message);
    }

    if (window.dispatchLog) window.dispatchLog(message, 'warn', options);
};

export const captureInfoMessage = (message = '', options = null) => {
    if (window.dispatchLog) window.dispatchLog(message, 'info', options);
};
