import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Modal, { ModalButton } from '../../Modal';
import { colors } from '../../../utils';
import { Space } from '../../common/Space';

const OptOutRatio = styled.div`
    margin-bottom: 12px;
    display: flex;
    align-items: start;
    position: relative;

    small {
        display: block;
        margin-top: 4px;
        color: ${colors.gray.basic};
    }

    [type='radio'] {
        position: relative;
        left: 15px;
        top: -4px;
        z-index: 0;
        -webkit-appearance: none;
    }
    [type='radio'] + label {
        font-size: 0.85em;
        padding-left: 28px;
        font-weight: 400;
    }
    [type='radio'] + label::before {
        width: 20px;
        height: 20px;
        margin-left: -28px;
        border-radius: 50%;
        margin-top: 4px;
        border: 2px solid ${colors.gray.basic};
        background-color: #fff;
        display: block;
        content: '';
        float: left;
        margin-right: 5px;
        z-index: 5;
        position: relative;
    }
    [type='radio']:checked + label::before {
        box-shadow: inset 0 0 0 3px #fff;
        background: ${colors.gray.basic};
    }
`;

export default function OptOutModal({
    localization,
    onModalOptOutClose,
    onModalOptOutConfirm
}) {
    const [optOutType, setOptOutType] = useState(1);

    const onConfirm = () => {
        onModalOptOutConfirm(optOutType === 2);
    };

    return (
        <Modal
            title={localization.getText('trainer.opt_in_out.opt_out_modal.title')}
            onClose={onModalOptOutClose}>
            <OptOutRatio>
                <input
                    type="radio"
                    id="opt_out_option1"
                    name="optOutType"
                    checked={optOutType === 1}
                    onChange={() => setOptOutType(1)}
                />
                <label htmlFor="opt_out_option1">
                    {localization.getText('trainer.opt_in_out.opt_out_modal.option1')}
                    <small>
                        {localization.getText(
                            'trainer.opt_in_out.opt_out_modal.option1_note'
                        )}
                    </small>
                </label>
            </OptOutRatio>

            <Space $size={4} />

            <OptOutRatio>
                <input
                    type="radio"
                    id="opt_out_option2"
                    name="optOutType"
                    checked={optOutType === 2}
                    onChange={() => setOptOutType(2)}
                />
                <label htmlFor="opt_out_option2">
                    {localization.getText('trainer.opt_in_out.opt_out_modal.option2')}
                    <small>
                        {localization.getText(
                            'trainer.opt_in_out.opt_out_modal.option2_note'
                        )}
                    </small>
                </label>
            </OptOutRatio>

            <Space $size={10} />
            <ModalButton onClick={onConfirm} $color={colors.green.flashygreen}>
                <span>{localization.getText('trainer.opt_in_out.confirm')}</span>
            </ModalButton>
            <Space $size={6} />
            <ModalButton
                onClick={onModalOptOutClose}
                $isOutline
                $color={colors.green.flashygreen}>
                <span>{localization.getText('trainer.opt_in_out.cancel')}</span>
            </ModalButton>
        </Modal>
    );
}

OptOutModal.propTypes = {
    localization: PropTypes.object.isRequired,
    onModalOptOutClose: PropTypes.func.isRequired,
    onModalOptOutConfirm: PropTypes.func.isRequired
};
