import { doRefreshToken, types as authTypes } from '../actions/auth';
import { getUserProfile } from '../actions/user';
import { reload } from '../actions/common';
import { errorCodes } from '../../../utils';
import { ERR_REFRESH_FAILED } from '../../constants';
import {
    hasExceededAuthFailLimit,
    incrementAuthFailCount,
    resetAuthFailCount,
    setAppPaused
} from '../../utils/general';

export const REFRESH_TOKEN_THRESHOLD = 15 * 60 * 1000; // 15 min

export default store => {
    const { dispatch, getState } = store;
    return next => action => {
        const nextAction = next(action);

        const state = getState();

        switch (action.type) {
            case authTypes.AUTH_SUCCESS: {
                // Refresh token every 15 min
                setInterval(
                    () => doRefreshToken(getState().auth, false, dispatch),
                    REFRESH_TOKEN_THRESHOLD
                );

                dispatch(getUserProfile());

                break;
            }
            case authTypes.AUTH_FAIL: {
                if (
                    !state.auth.authWithPassword ||
                    action.payload.message === ERR_REFRESH_FAILED
                ) {
                    if (hasExceededAuthFailLimit()) {
                        resetAuthFailCount();
                        setAppPaused(true);
                        document.location.reload();
                    } else {
                        incrementAuthFailCount();

                        dispatch(
                            reload({
                                code: errorCodes.background.AUTH_FAILED,
                                reason: 'AUTH_FAILED'
                            })
                        );
                    }
                }
                break;
            }
            default:
        }

        return nextAction;
    };
};
