import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper, Button, StartIcon, EndIcon } from './styled';

function CallButton({ isCallStarted, sendManualCallEvent, localization }) {
    const getButtonLabel = () => {
        return localization.getText(
            isCallStarted ? 'call_buttons.end' : 'call_buttons.start'
        );
    };
    const getButtonIcon = () => {
        return isCallStarted ? <EndIcon /> : <StartIcon />;
    };

    const onButtonClick = () => {
        sendManualCallEvent(!isCallStarted);
    };

    return (
        <Wrapper>
            <Button $isCallStarted={isCallStarted} onClick={onButtonClick}>
                {getButtonIcon()}
                <span>{getButtonLabel()}</span>
            </Button>
        </Wrapper>
    );
}

CallButton.propTypes = {
    isCallStarted: PropTypes.bool.isRequired,
    sendManualCallEvent: PropTypes.func.isRequired,
    localization: PropTypes.object.isRequired
};

export default CallButton;
