import styled from 'styled-components';
import { colors } from '../../../utils';

export const FormGroup = styled.div`
    margin-bottom: 12px;
    width: 100%;

    label {
        margin-bottom: 4px;
        display: block;
        font-size: 13px;
        font-weight: 600;
        color: #354053;
    }
`;

export const SelectStyles = {
    control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: state.isFocused ? 'grey' : 'transparent',
        backgroundColor: colors.white.desert_storm
    })
};
