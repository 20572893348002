import { connect } from 'react-redux';
import { CallSimulator } from '../../components/CallSimulator/CallSimulator';
import withLocalization from '../../localization/withLocalization';

// eslint-disable-next-line no-unused-vars
const mapStateToProps = state => ({
    jwtToken: state.user.token
});

// eslint-disable-next-line no-unused-vars
const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withLocalization(CallSimulator));
