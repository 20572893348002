import React from 'react';

export function CallSimulatorImg() {
    return (
        <div
            style={{
                position: 'relative',
                textAlign: 'center'
            }}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="injected-svg js-svg-injector"
                width="120"
                height="120"
                viewBox="0 0 120 120">
                <style>{`.icon-18-0{fill:none;stroke:#bdc5d1}.icon-18-1{fill:none;stroke:#fff}.icon-18-2{fill:none;stroke:#377dff}.icon-18-3{fill:none;stroke:#bdc5d1}`}</style>
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    strokeWidth="3"
                    d="M71.3 78.1h26.3l3 7.2H68.2z"
                    className="icon-18-0 fill-none stroke-gray-400"
                />
                <g opacity=".5">
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        strokeWidth="3"
                        d="M92 18.4h12.1c3.1 0 5.5 2.5 5.5 5.5v64.2M50.4 96.7H7.1c-3.1 0-5.5-2.5-5.5-5.5V23.9c0-3.1 2.5-5.5 5.5-5.5h38.3M1.5 79.8h64.7M102 79.8h7M42 96.7l-3.2 15.2M50.4 111.9H27.8"
                        className="icon-18-0 fill-none stroke-gray-400"
                    />
                </g>
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    strokeWidth="3"
                    d="M42.5 45.3c.4-1.3.6-2.7.6-4.2 0-6.9-5-12-11.1-12s-11 5.2-11 12.1c0 1.5.2 2.9.6 4.2-.9.6-1.4 1.6-1.4 2.7 0 1.5 1 2.7 2.3 3.1 1.6 4.7 5.3 8.1 9.6 8.1s8-3.3 9.6-8.1C43 50.7 44 49.5 44 48c0-1.1-.6-2.1-1.5-2.7z"
                    className="icon-18-0 fill-none stroke-gray-400"
                />
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    strokeWidth="3"
                    d="M39.8 40.6c-.3-1.3-1.2-1.3-2.3-1.3-.4 0-.6-2.9-.6-2.9s-1.2 0-3.2 1.8c-4.2 3.7-8.8.8-8.8.8M41.7 64.1l10.5 4.4c1.9 1 2.7 2.9 2.7 4.9v6"
                    className="icon-18-0 fill-none stroke-gray-400"
                />
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    strokeWidth="3"
                    d="m32.2 65.6 4.1 4.6 5.5-5.9-2.4-4.1"
                    className="icon-18-2 fill-none stroke-primary"
                />
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    strokeWidth="3"
                    d="m22.7 64.1-10.3 4.2c-.7.4-1.2.9-1.6 1.5-.7 1-1.1 2.1-1.1 3.4v6.2"
                    className="icon-18-0 fill-none stroke-gray-400"
                />
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    strokeWidth="3"
                    d="m32.2 65.6-4 4.6-5.5-5.9 2.3-4.1M39.4 60.2l-7.2 5.4M25 60.2l7.2 5.4"
                    className="icon-18-2 fill-none stroke-primary"
                />
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    strokeWidth="3"
                    d="M63.1 109.8v8.2M106.7 109.8v8.2M90.3 78.1V76c3.7-2 6.6-5.8 8.2-10.5 2-.6 3.5-2.4 3.5-4.7 0-1.7-.9-3.2-2.1-4.1.6-2 1-4.1 1-6.2 0-10.4-7.5-18.1-16.7-18.1s-16.7 7.8-16.7 18.1c0 2.2.3 4.3 1 6.2-1.3.9-2.1 2.4-2.1 4.1 0 2.2 1.5 4.1 3.5 4.7 1.6 4.7 4.5 8.5 8.2 10.5v2.1M118.5 118V97.6c0-3-1.2-5.9-4.1-7.4l-13.7-5v.1M68.4 84.9l-13.9 5c-1 .6-1.8 1.4-2.5 2.3-1 1.5-1.6 3.2-1.6 5.1V118"
                    className="icon-18-0 fill-none stroke-gray-400"
                />
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    strokeWidth="3"
                    d="m97.3 100.2-11.4 11.4M96 108.5l-3.8 3.8"
                    className="icon-18-3 fill-none stroke-gray-400"
                    opacity=".5"
                />
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    strokeWidth="3"
                    d="M99.9 56.8c-1.2 3.5-4.8 6.7-7 8.4-.3.2-2.4-2.5-2.4-2.5s-1.3 4.8-2 5c-6.4 2.4-14.3-.6-20.1-11"
                    className="icon-18-0 fill-none stroke-gray-400"
                />
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    strokeWidth="3"
                    d="M87.2 7.5h-38c-2.4 0-4.3 1.9-4.3 4.3v14.3c0 2.4 1.9 4.3 4.3 4.3h3.6v7l11.1-7h23.3c2.4 0 4.3-1.9 4.3-4.3V11.8c0-2.4-1.9-4.3-4.3-4.3z"
                    className="fill-gray-400"
                    opacity=".5"
                    style={{ fill: '#bdc5d1' }}
                />
                <path
                    d="M81.4 2h-38c-2.4 0-4.3 1.9-4.3 4.3v14.3c0 2.4 1.9 4.3 4.3 4.3H47v7l11.1-7h23.3c2.4 0 4.3-1.9 4.3-4.3V6.3c0-2.4-1.9-4.3-4.3-4.3z"
                    className="fill-primary"
                    style={{ fill: '#377dff' }}
                />
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    strokeWidth="3"
                    d="M73.9 10.3h-23M56.2 16.5h-5.3"
                    className="icon-18-1 fill-none stroke-white"
                />
            </svg>
        </div>
    );
}
