import {
    UPDATE_NICK_NAME_REQUEST,
    DO_LOGIN_REQUEST,
    SET_LOCALE_REQUEST,
    LOGOUT
} from './actionTypes/user';

export const updateUsername = nickName => ({
    type: UPDATE_NICK_NAME_REQUEST,
    payload: { nickName }
});

export const updateLocale = locale => ({
    type: SET_LOCALE_REQUEST,
    payload: {
        locale
    }
});

export const doLogin = (locationName, username, password) => ({
    type: DO_LOGIN_REQUEST,
    payload: {
        locationName,
        username,
        password
    }
});

export const doLogout = () => ({
    type: LOGOUT
});
