import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Modal, { ModalButton } from '../../Modal';
import { colors } from '../../../utils';
import { Space } from '../../common/Space';

const OptInCheckBox = styled.div`
    margin-bottom: 12px;
    display: flex;
    align-items: start;
    position: relative;

    [type='checkbox'] {
        position: relative;
        left: 15px;
        top: -4px;
        z-index: 0;
        -webkit-appearance: none;
    }
    [type='checkbox'] + label {
        font-size: 0.85em;
        padding-left: 28px;
        font-weight: 400;
    }
    [type='checkbox'] + label::before {
        width: 20px;
        height: 20px;
        margin-left: -28px;
        border-radius: 2px;
        margin-top: 4px;
        border: 2px solid ${colors.gray.jumbo};
        background-color: #fff;
        display: block;
        content: '';
        float: left;
        margin-right: 5px;
        z-index: 5;
        position: relative;
    }
    [type='checkbox']:checked + label::before {
        border: 2px solid ${colors.green.flashygreen};
        background-color: ${colors.green.flashygreen};
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24'%3E%3Cpath d='M20.29 2 9 13.57 3.71 8.56 0 12.27 9 21 24 5.71z' fill='%23ffffff'/%3E%3C/svg%3E");
        background-position: center center;
        background-size: contain;
    }
`;

export default function OptInModal({
    localization,
    onModalOptInClose,
    onModalOptInConfirm
}) {
    const [optInCheck1, setOptInCheck1] = useState(false);
    const optInCheck1Click = () => setOptInCheck1(!optInCheck1);

    const [optInCheck2, setOptInCheck2] = useState(false);
    const optInCheck2Click = () => setOptInCheck2(!optInCheck2);

    return (
        <Modal
            title={localization.getText('trainer.opt_in_out.opt_in_modal.title')}
            onClose={onModalOptInClose}>
            <OptInCheckBox>
                <input
                    type="checkbox"
                    id="opt_in_option1"
                    checked={optInCheck1}
                    onChange={optInCheck1Click}
                />
                <label htmlFor="opt_in_option1">
                    {localization.getText('trainer.opt_in_out.opt_in_modal.option1')}
                </label>
            </OptInCheckBox>

            <Space $size={4} />

            <OptInCheckBox>
                <input
                    type="checkbox"
                    id="opt_in_option2"
                    checked={optInCheck2}
                    onChange={optInCheck2Click}
                />
                <label htmlFor="opt_in_option2">
                    {localization.getText('trainer.opt_in_out.opt_in_modal.option2')}
                </label>
            </OptInCheckBox>

            <Space $size={10} />

            <ModalButton
                disabled={!optInCheck1 || !optInCheck2}
                onClick={onModalOptInConfirm}
                $color={colors.green.flashygreen}
                $disabledColor={colors.green.magic_mint}>
                <span>{localization.getText('trainer.opt_in_out.confirm')}</span>
            </ModalButton>
            <Space $size={6} />
            <ModalButton
                onClick={onModalOptInClose}
                $isOutline
                $color={colors.green.flashygreen}>
                <span>{localization.getText('trainer.opt_in_out.cancel')}</span>
            </ModalButton>
        </Modal>
    );
}

OptInModal.propTypes = {
    localization: PropTypes.object.isRequired,
    onModalOptInClose: PropTypes.func.isRequired,
    onModalOptInConfirm: PropTypes.func.isRequired
};
