import orderBy from 'lodash/orderBy';

export const DEFAULT_WHITELIST = 'default-whitelist';
export const CLICKABLE_WHITELIST = 'clickable-whitelist';
export const DETECTED_WHITELIST = 'detected-whitelist';
export const DEFAULT_BLACKLIST = 'default-blacklist';
export const CLICKABLE_BLACKLIST = 'clickable-blacklist';

export const SUPER_KEYWORD_THRESHOLD = 3;

export const getPillType = (type, count) => {
    let pillType = '';
    if (type === 'whitelist') {
        if (!count) {
            pillType = DEFAULT_WHITELIST;
        } else {
            pillType = DETECTED_WHITELIST;
        }
    } else {
        pillType = DEFAULT_BLACKLIST;
    }
    return pillType;
};

export const getSortedDetection = (list, countable = true, order = 'desc') => {
    if (!list) return;
    return orderBy(list, countable ? 'count' : word => word.count !== 0, [order]);
};

export const getDetectedWords = (list = []) => {
    return list.filter(text => text.count > 0);
};

export const phraseTypes = {
    SPOTTED_PHRASES: 'SPOTTED_PHRASES',
    TOTAL_PHRASES: 'TOTAL_PHRASES'
};
