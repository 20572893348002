export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_FAIL = 'GET_USER_PROFILE_FAIL';
export const HIDE_SAVED = 'HIDE_SAVED';

export const UPDATE_NICK_NAME_REQUEST = 'UPDATE_NICK_NAME_REQUEST';
export const UPDATE_NICK_NAME_SUCCESS = 'UPDATE_NICK_NAME_SUCCESS';
export const UPDATE_NICK_NAME_FAIL = 'UPDATE_NICK_NAME_FAIL';

export const SET_LOCALE_REQUEST = 'SET_LOCALE_REQUEST';
export const SET_LOCALE_SUCCESS = 'SET_LOCALE_SUCCESS';
export const SET_LOCALE_FAIL = 'SET_LOCALE_FAIL';

export const SET_USER_SCORES_SUCCESS = 'SET_USER_SCORES_SUCCESS';
export const SET_USER_SCORE_FAILED = 'SET_USER_SCORE_FAILED';

export const DO_LOGIN_REQUEST = 'DO_LOGIN_REQUEST';
export const DO_LOGIN_SUCCESS = 'DO_LOGIN_SUCCESS';
export const DO_LOGIN_FAILED = 'DO_LOGIN_FAILED';
export const TOKEN_REFRESH_SUCCESS = 'TOKEN_REFRESH_SUCCESS';
export const AUTH_WITH_PASSWORD_REQUIRED = 'AUTH_WITH_PASSWORD_REQUIRED';
export const LOGOUT = 'LOGOUT';
