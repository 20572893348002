import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import Background from './background';
import configureStore from './store';
import { App, ModalApp, LaunchScreen } from './containers';
import { IS_ELECTRON_APP, IS_UI_ONLY_MODE } from './config/electron';
import { isAppPaused } from './background/utils/general';

const { store, persistor } = configureStore();
if (isAppPaused()) {
    ReactDOM.render(
        <PersistGate persistor={persistor}>
            <Provider store={store}>
                <LaunchScreen />
            </Provider>
        </PersistGate>,
        document.getElementById('main')
    );
} else if (IS_UI_ONLY_MODE && !IS_ELECTRON_APP && window.name !== 'targetWindow') {
    ReactDOM.render(
        <PersistGate persistor={persistor}>
            <Provider store={store}>
                <ModalApp />
            </Provider>
        </PersistGate>,
        document.getElementById('main')
    );
} else {
    const background = new Background();
    background.initialize(store);

    ReactDOM.render(
        <PersistGate persistor={persistor}>
            <Provider store={store}>
                <App />
            </Provider>
        </PersistGate>,
        document.getElementById('main')
    );
}
