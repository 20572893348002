export const SET_BLACKLIST = 'SET_BLACKLIST';
export const SET_WHITELIST = 'SET_WHITELIST';
export const SET_SPEECH_RATE = 'SET_SPEECH_RATE';
export const SET_NEW_SPEECH_RATE = 'SET_NEW_SPEECH_RATE';
export const SET_SPEECH_PACE = 'SET_SPEECH_PACE';
export const SET_SPEECH_RATIO = 'SET_SPEECH_RATIO';
export const SET_LOUDNESS = 'SET_LOUDNESS';
export const SET_TIMELINE_TRANSCRIPT = 'SET_TIMELINE_TRANSCRIPT';
export const SET_TRANSCRIPT_COPY_BUTTON_AVAILABILITY =
    'SET_TRANSCRIPT_COPY_BUTTON_AVAILABILITY';
export const SET_PHRASE_INTEGRATION_WORDS = 'SET_PHRASE_INTEGRATION_WORDS';
export const TOGGLE_TRANSCRIPT_COPY_BUTTON = 'TOGGLE_TRANSCRIPT_COPY_BUTTON';
export const TOGGLE_TRANSCRIPT = 'TOGGLE_TRANSCRIPT';
export const SET_IS_STARTED = 'SET_IS_STARTED';
export const SET_ELAPSED_SECONDS = 'SET_ELAPSED_SECONDS';
export const SET_CALL_RESULT = 'SET_CALL_RESULT';
export const SET_RESULT_POPUP_VISIBILITY = 'SET_RESULT_POPUP_VISIBILITY';
export const SET_RESULT_POPUP_VISIBILITY_ON_LOAD = 'SET_RESULT_POPUP_VISIBILITY_ON_LOAD';
export const RELOAD = 'RELOAD';
export const INIT = 'TRAINER_INIT';
export const USER_RELOAD = 'USER_RELOAD';
export const UPDATE_RELOAD_COUNT_DOWN = 'UPDATE_RELOAD_COUNT_DOWN';
export const SET_CALL_RESULT_TIMER = 'SET_CALL_RESULT_TIMER';
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';
export const WINDOW_ON_LOAD = 'WINDOW_ON_LOAD';
export const SET_USER_INTERFACE_CONFIG = 'SET_USER_INTERFACE_CONFIG';
export const COPY_TO_CLIPBOARD = 'COPY_TO_CLIPBOARD';
export const HIDE_NEW_CALL_MESSAGE = 'HIDE_NEW_CALL_MESSAGE';
export const DEVICE_LIST_RECEIVE_ERROR = 'DEVICE_LIST_RECEIVE_ERROR';
export const DEVICE_LIST_RECEIVED = 'DEVICE_LIST_RECEIVED';

export const RESUME_TRAINER = 'RESUME_TRAINER';

export const SET_SNOOZE_TIMER_SUCCESS = 'SET_SNOOZE_TIMER_SUCCESS';
export const SET_SNOOZE_TIMER_FAIL = 'SET_SNOOZE_TIMER_FAIL';

export const SNOOZE_TRAINER = 'SNOOZE_TRAINER';
export const ADD_SNOOZE_TIMER = 'ADD_SNOOZE_TIMER';
export const SET_INFINITY_SNOOZE = 'SET_INFINITY_SNOOZE';

export const SEND_MANUAL_CALL_EVENT = 'SEND_MANUAL_CALL_EVENT';
export const SET_MANUAL_BUTTON_AVAILABILITY = 'SET_MANUAL_BUTTON_AVAILABILITY';

export const SET_RECORD_CALL = 'SET_RECORD_CALL';

export const CONFIGURATION_LOADED = 'CONFIGURATION_LOADED';

export const SET_ASR_CONFIG_ERRORS = 'SET_ASR_CONFIG_ERRORS';
export const MANUAL_OPT_IN = 'MANUAL_OPT_IN';
export const MANUAL_OPT_OUT = 'MANUAL_OPT_OUT';
export const MANUAL_OPT_STOP = 'MANUAL_OPT_STOP';

export const EVENT_OPT_IN = 'EVENT_OPT_IN';
export const EVENT_OPT_OUT = 'EVENT_OPT_OUT';
export const EVENT_OPT_STOP = 'EVENT_OPT_STOP';

export const OPT_RESET = 'OPT_RESET';

export const CALL_HOLD = 'CALL_HOLD';
export const CALL_UNHOLD = 'CALL_UNHOLD';

export const CALL_NOTIFICATION = 'CALL_NOTIFICATION';

export const SAVE_CONVERSION = 'SAVE_CONVERSION';
