import styled from 'styled-components';
import { colors } from '../../../utils';

export const CloseButton = styled.button`
    background: transparent;
    border: none;
    font-size: 20px;
    line-height: 20px;
    cursor: pointer;
    color: ${colors.gray.dark};
    margin: 0;
    padding: 0;
`;

export const Title = styled.h2`
    margin: 0;
    font-size: 1.4em;
    text-align: center;
    font-weight: normal;
`;

export const Description = styled.div`
    margin: 10px 10px 20px 10px;
    text-align: center;
    font-weight: normal;
    font-size: 0.9em;
`;

export const HeaderWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 8px;
    margin-bottom: 8px;
    border-bottom: 1px solid ${colors.gray.gray};
`;

export const ProjectLabel = styled.div`
    color: ${colors.gray.iron};
    font-size: 10px;
`;
export const ProjectName = styled.div`
    font-size: 14px;
`;
