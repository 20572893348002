import styled from 'styled-components';

export const Wrappers = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    width: 100%;
    height: 100%;
    align-content: center;
    align-items: center;
    box-shadow: 0 2px 6px 0 #cdd3df;
    border-radius: 4px;
    background: white;
    padding: 10px 10px;
`;

export const CallSimulatorWrapper = styled.div`
    height: 100vh;
    padding: 50px 10px 10px 10px;
`;
