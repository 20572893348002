import store from 'store2';
import storeFactory from './store';
import * as errors from './errors';
import { sendLog } from './store/actions/common';
import { doRefreshToken } from './store/actions/auth';
import { version } from '../../package.json';

window.errors = errors;
const debug = store.namespace('debug');
window.debug = debug;

// TODO: livenessCounter needs to be removed.
const livenessChecks = () => {
    setInterval(() => {
        window.livenessCounter++;
    }, 3000);
};

// TODO: show authorized user name and company in UI
export default class Background {
    initialize(frontendStore) {
        this._store = storeFactory(frontendStore);

        window.debugModeOn = debug.get('disableLivenessCheck', false);
        window.livenessCounter = 0;
        livenessChecks();

        window.dispatchLog = (error, level = 'error', options = undefined) =>
            this._store.dispatch(sendLog(this._store.getState(), error, level, options));
        window.dispatch2background = action => this._store.dispatch(action);

        window.dispatchLog(`Version: ${version}`, 'info');

        // try to login with refreshToken
        doRefreshToken(this._store.getState().auth, true, this._store.dispatch);
    }
}
