import styled from 'styled-components';

import { colors } from '../../../utils';

const TranscriptWrapper = styled.div`
    display: flex;
    width: 100%;
    margin: 10px 0;
    flex-direction: column;
    align-items: flex-start;
    overflow-y: auto;
    flex-grow: 1;
    flex-shrink: 0;
    height: 1%;
    padding: 0 2px;

    & > div {
        width: 90%;
        border-radius: 4px;
        padding: 12px;
        margin-top: 10px;
        font-size: 14px;
    }

    & > div:first-child {
        margin-top: 0;
    }

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background: ${colors.gray.iron};
        border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 4px;
    }
`;

export default TranscriptWrapper;
