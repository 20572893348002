import { connect } from 'react-redux';
import Login from '../../components/Login';
import { doLogin } from '../../store/actions/user';

const mapStateToProps = state => ({
    pending: state.user.authWithPasswordPending,
    error: state.user.authWithPasswordError
});

export default connect(mapStateToProps, {
    doLogin
})(Login);
