import { get } from 'lodash';
import { types as authTypes } from '../actions/auth';
import { types as callTypes } from '../actions/call';
import { types as asrTypes } from '../actions/asr';
import { types as mainTypes } from '../actions/main';
import { types as configTypes } from '../actions/config';
import { types as userTypes } from '../actions/user';
import { types as commonTypes } from '../actions/common';
import { types as snoozeTypes } from '../actions/snooze';
import { types as battleCardTypes } from '../actions/battleCard';

import {
    geCallMode,
    getAsrErrorState,
    getCallId,
    getCountDown,
    getDefaultCallMode,
    getLatestAsrResults,
    getSilencDetectionThreshold,
    getSuggestionCallFrequency,
    getUserScores,
    getUserScoresError,
    shouldNotifyUiAboutReload,
    shouldSendSystemSoundToAsr
} from '../selectors';

import * as frontCrxTypes from '../../../store/actions/actionTypes/CRX';
import * as frontTrainerTypes from '../../../store/actions/actionTypes/trainer';
import * as frontUserTypes from '../../../store/actions/actionTypes/user';
import * as frontCallSuggestionsTypes from '../../../store/actions/actionTypes/suggestions';
import * as frontSalesTypes from '../../../store/actions/actionTypes/sales';

import { openBattleCard } from '../../../store/actions/battleCard';
import { CallMode, ERR_INVALID_AUTH_SCHEME } from '../../constants';

export default frontendStore => store => {
    return next => action => {
        const result = next(action);
        const state = store.getState();

        const { type, payload } = action;

        const dispatch2frontend = frontendStore.dispatch;

        switch (type) {
            /*
            AUTH EVENTS
             */
            case authTypes.TOKEN_REFRESH_SUCCESS: {
                dispatch2frontend({
                    type: frontUserTypes.TOKEN_REFRESH_SUCCESS,
                    payload: {
                        token: payload.token
                    }
                });
                break;
            }
            case authTypes.AUTH_SUCCESS: {
                dispatch2frontend({
                    type: frontUserTypes.DO_LOGIN_SUCCESS,
                    payload: {
                        token: payload.token,
                        isPasswordAuth: payload.isPasswordAuth
                    }
                });
                break;
            }

            case authTypes.AUTH_FAIL: {
                switch (payload.message) {
                    case ERR_INVALID_AUTH_SCHEME: {
                        dispatch2frontend({
                            type: frontUserTypes.AUTH_WITH_PASSWORD_REQUIRED
                        });

                        break;
                    }
                    default: {
                        if (state.auth.authWithPassword) {
                            dispatch2frontend({
                                type: frontUserTypes.DO_LOGIN_FAILED,
                                payload
                            });
                        }
                    }
                }
                break;
            }

            case authTypes.AUTH_WITH_PASSWORD_REQUIRED: {
                dispatch2frontend({
                    type: frontUserTypes.AUTH_WITH_PASSWORD_REQUIRED
                });
                break;
            }

            /*
            COMMON EVENTS
             */
            case commonTypes.SHOW_NOTIFICATION: {
                dispatch2frontend({
                    type: frontTrainerTypes.SHOW_NOTIFICATION,
                    payload: { ...payload }
                });
                break;
            }
            case commonTypes.RELOAD: {
                dispatch2frontend({
                    type: frontTrainerTypes.RELOAD,
                    payload: { ...payload }
                });
                break;
            }
            case commonTypes.UPDATE_RELOAD_COUNT_DOWN: {
                if (shouldNotifyUiAboutReload(state)) {
                    dispatch2frontend({
                        type: frontTrainerTypes.UPDATE_RELOAD_COUNT_DOWN,
                        payload: {
                            countdown: getCountDown(state)
                        }
                    });
                }
                break;
            }
            case battleCardTypes.OPEN_BATTLE_CARD: {
                dispatch2frontend(openBattleCard(payload));
                break;
            }

            case mainTypes.DAEMON_VERSION_RECEIVED:
                dispatch2frontend({
                    type: frontCrxTypes.RECEIVE_DAEMON_VERSION,
                    payload: {
                        daemonVersion: payload.version
                    }
                });
                break;

            case snoozeTypes.SNOOZE_COUNTDOWN:
            case snoozeTypes.SET_SNOOZE_TIMER_SUCCESS: {
                dispatch2frontend({
                    type: frontTrainerTypes.SET_SNOOZE_TIMER_SUCCESS,
                    payload
                });
                break;
            }
            case snoozeTypes.SET_SNOOZE_TIMER_FAIL: {
                dispatch2frontend({
                    type: frontTrainerTypes.SET_SNOOZE_TIMER_FAIL,
                    payload: {
                        error: action.error
                    }
                });
                break;
            }

            /*
            SETTINGS EVENTS
             */

            case configTypes.GET_APP_CONFIG_SUCCESS: {
                const { config } = payload;
                const { integrationMethod, explicitIntegrationSource } = get(
                    config,
                    'callDetection',
                    {}
                );
                dispatch2frontend({
                    type: frontTrainerTypes.SET_USER_INTERFACE_CONFIG,
                    payload: {
                        enableTrainer: !config.disableTrainer,
                        enableSales: config.enableSales || false,
                        enableCallSimulator: config.enableCallSimulator || false,
                        userInterfaceConfig: {
                            ...config.userInterface,
                            integrationMethod,
                            explicitIntegrationSource
                        },
                        bonusScore: state.config.bonusScore,
                        silenceDetectionThreshold: getSilencDetectionThreshold(state),
                        suggestionCallFrequency: getSuggestionCallFrequency(state)
                    }
                });
                break;
            }

            case userTypes.GET_USER_PROFILE_SUCCESS: {
                dispatch2frontend({
                    type: frontUserTypes.GET_USER_PROFILE_SUCCESS,
                    payload
                });
                break;
            }

            case mainTypes.DEVICE_LIST_RECEIVE_ERROR: {
                dispatch2frontend({
                    type: frontTrainerTypes.DEVICE_LIST_RECEIVE_ERROR
                });
                break;
            }
            case mainTypes.DEVICE_LIST_RECEIVED: {
                dispatch2frontend({
                    type: frontTrainerTypes.DEVICE_LIST_RECEIVED,
                    payload
                });
                break;
            }

            case userTypes.SET_USER_SCORES_SUCCESS: {
                dispatch2frontend({
                    type: frontUserTypes.SET_USER_SCORES_SUCCESS,
                    payload: getUserScores(state)
                });
                break;
            }
            case userTypes.SET_USER_SCORES_FAIL: {
                dispatch2frontend({
                    type: frontUserTypes.SET_USER_SCORE_FAILED,
                    error: getUserScoresError(state)
                });
                break;
            }
            case userTypes.SET_USER_LOCALE_SUCCESS: {
                dispatch2frontend({
                    type: frontUserTypes.SET_LOCALE_SUCCESS
                });
                break;
            }
            case userTypes.SET_USER_LOCALE_FAIL: {
                dispatch2frontend({
                    type: frontUserTypes.SET_LOCALE_FAIL,
                    error: getUserScoresError(state)
                });
                break;
            }
            case userTypes.SET_USER_USERNAME_SUCCESS: {
                dispatch2frontend({
                    type: frontUserTypes.UPDATE_NICK_NAME_SUCCESS
                });
                break;
            }
            case userTypes.SET_USER_USERNAME_FAIL: {
                dispatch2frontend({
                    type: frontUserTypes.UPDATE_NICK_NAME_FAIL,
                    error: getUserScoresError(state)
                });
                break;
            }

            /*
            ASR EVENTS
             */
            case asrTypes.RECOGNITION_READY: {
                dispatch2frontend({
                    type: frontTrainerTypes.SET_MANUAL_BUTTON_AVAILABILITY,
                    payload: {
                        isAvailable: true
                    }
                });
                break;
            }
            case commonTypes.ALL_SETTINGS_RECEIVED: {
                dispatch2frontend({
                    type: frontCrxTypes.RECEIVE_CRX_INITIAL_CALL_DATA,
                    payload: getLatestAsrResults(state)
                });

                dispatch2frontend({
                    type: frontCrxTypes.RECEIVE_CRX_ASR_ERROR_DATA,
                    payload: getAsrErrorState(state)
                });
                break;
            }
            case asrTypes.UPDATE_USER_ASR_CONFIG_SUCCESS: {
                dispatch2frontend({
                    type: frontCrxTypes.RECEIVE_CRX_ASR_ERROR_DATA,
                    payload: getAsrErrorState(state)
                });
                break;
            }

            /*
            CALL EVENTS
             */
            case callTypes.CALL_TIMER_UPDATED:
                dispatch2frontend({
                    type: frontCrxTypes.RECEIVE_CRX_CALL_DATA,
                    payload: getLatestAsrResults(state)
                });
                break;
            case callTypes.CALL_HOLDED: {
                dispatch2frontend({ type: frontTrainerTypes.CALL_HOLD });
                break;
            }
            case callTypes.CALL_UNHOLDED: {
                dispatch2frontend({ type: frontTrainerTypes.CALL_UNHOLD });
                break;
            }
            case callTypes.CALL_FEEDING_STARTED: {
                window.callFeedingStarted = true; // sorry for this
                dispatch2frontend({
                    type: frontCrxTypes.RECEIVE_CRX_CALL_STARTED,
                    payload: {
                        systemSoundRecognition: shouldSendSystemSoundToAsr(state),
                        callId: getCallId(state)
                    }
                });
                break;
            }
            case callTypes.CALL_ENDED: {
                if (window.callFeedingStarted) {
                    window.callFeedingStarted = false;
                    dispatch2frontend({
                        type: frontCrxTypes.RECEIVE_CRX_CALL_ENDED,
                        payload: getLatestAsrResults(state)
                    });
                }
                break;
            }
            case configTypes.SET_RECORD_CALL: {
                dispatch2frontend({
                    type: frontTrainerTypes.SET_RECORD_CALL,
                    payload: {
                        recordCall: payload.recordCall
                    }
                });
                break;
            }
            case callTypes.RESET_CALL_MODE: {
                dispatch2frontend({
                    type: frontTrainerTypes.OPT_RESET
                });
                break;
            }
            case callTypes.CALL_SET_MODE: {
                const mode =
                    payload.mode === CallMode.DEFAULT
                        ? getDefaultCallMode(state)
                        : payload.mode;

                const currentMode = geCallMode(state);

                switch (mode) {
                    case CallMode.BOTH:
                    case CallMode.CUSTOMER: {
                        dispatch2frontend({
                            type: frontTrainerTypes.EVENT_OPT_IN
                        });
                        break;
                    }
                    case CallMode.AGENT:
                        if (
                            currentMode === CallMode.BOTH ||
                            currentMode === CallMode.CUSTOMER
                        ) {
                            if (payload.forgetAudioAndTranscript) {
                                dispatch2frontend({
                                    type: frontTrainerTypes.EVENT_OPT_OUT
                                });
                            } else {
                                dispatch2frontend({
                                    type: frontTrainerTypes.EVENT_OPT_STOP
                                });
                            }
                        }
                        break;
                    case CallMode.NONE: {
                        dispatch2frontend({
                            type: frontTrainerTypes.EVENT_OPT_STOP
                        });
                        break;
                    }
                    default:
                }

                break;
            }
            case callTypes.CALL_NOTIFICATION: {
                dispatch2frontend({
                    type: frontTrainerTypes.CALL_NOTIFICATION,
                    payload // {eventName: 'call_start_phrase'|'call_end_phrase'|...}
                });
                break;
            }

            /*
            OTHER
             */
            case callTypes.GET_NEW_SUGGESTIONS_SUCCESS: {
                dispatch2frontend({
                    type: frontCallSuggestionsTypes.NEW_SUGGESTIONS,
                    payload
                });
                break;
            }
            case commonTypes.SALES_TRACKING_STATUS: {
                dispatch2frontend({
                    type: frontSalesTypes.SET_SALE_TRACKING_STATUS,
                    payload
                });
                break;
            }
            default:
        }

        return result;
    };
};
