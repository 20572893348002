import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { HashRouter as Router, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import Header from '../Header';
import { ErrorMessage } from '../../components/common';
import { Trainer, Settings, UserName, InactiveTrainer, Login, CallSimulator } from '..';
import withLocalization from '../../localization/withLocalization';
import {
    TRAINER_PAGE,
    SETTINGS_PAGE,
    LOGIN_PAGE,
    CALL_SIMULATOR_PAGE
} from '../../utils/routes';
import { userReload } from '../../store/actions/trainer';
import { NoDeviceFound } from '../../components/NoDeviceFound';
import { NoTeamCard } from '../../components/NoTeamCard';

class App extends Component {
    static propTypes = {
        localization: PropTypes.object.isRequired,
        errorCode: PropTypes.number,
        countdown: PropTypes.number.isRequired,
        requireOnboarding: PropTypes.bool.isRequired,
        isTrainerActive: PropTypes.bool.isRequired,
        deviceListError: PropTypes.bool.isRequired,
        reload: PropTypes.func.isRequired,
        isAuthenticated: PropTypes.bool.isRequired
    };

    componentDidMount() {
        // this prevents a reboot if the application starts successfully
        if (window.loadingTimeout) {
            clearTimeout(window.loadingTimeout);
        }

        // For health check
        window.coachLoaded = Date.now();
    }

    static defaultProps = {
        errorCode: null
    };

    HomeRoute = () => {
        return (
            <Route
                key="HomeRoute"
                path="/"
                exact
                render={() => <Redirect to={TRAINER_PAGE} />}
            />
        );
    };

    _renderErrorMessage() {
        const { errorCode, localization, countdown } = this.props;
        if (errorCode) {
            return (
                <ErrorMessage
                    localization={localization}
                    errorCode={errorCode}
                    countdown={countdown}
                />
            );
        }
    }

    _renderRoutes() {
        return (
            <Fragment>
                <Header showMenu={this.props.isAuthenticated} />
                {this._renderErrorMessage()}
                {this.HomeRoute()}
                <Route
                    key="TrainerRoute"
                    path={TRAINER_PAGE}
                    render={props => {
                        if (!this.props.isAuthenticated) {
                            return <Redirect to={LOGIN_PAGE} />;
                        }

                        return this.props.deviceListError ? (
                            <NoDeviceFound reload={this.props.reload} />
                        ) : (
                            <Fragment>
                                <div style={{ marginTop: 40 }} />
                                <NoTeamCard reload={this.props.reload} />
                                <Trainer {...props} />
                            </Fragment>
                        );
                    }}
                />
                <Route
                    key="SettingsRoute"
                    path={SETTINGS_PAGE}
                    render={props => {
                        if (!this.props.isAuthenticated) {
                            return <Redirect to={LOGIN_PAGE} />;
                        }

                        return <Settings {...props} />;
                    }}
                />
                <Route
                    key="CallSimulatorRoute"
                    path={CALL_SIMULATOR_PAGE}
                    render={props => {
                        if (!this.props.isAuthenticated) {
                            return <Redirect to={LOGIN_PAGE} />;
                        }

                        return <CallSimulator {...props} />;
                    }}
                />
                <Route
                    key="LoginRoute"
                    path={LOGIN_PAGE}
                    render={props => {
                        if (this.props.isAuthenticated) {
                            return <Redirect to={TRAINER_PAGE} />;
                        }

                        return <Login {...props} />;
                    }}
                />
            </Fragment>
        );
    }

    _renderOnboarding() {
        return <UserName />;
    }

    render() {
        const { isTrainerActive, requireOnboarding, isAuthenticated } = this.props;

        if (isAuthenticated) {
            if (!isTrainerActive) return <InactiveTrainer />;
            if (requireOnboarding) return this._renderOnboarding();
        }

        return <Router>{this._renderRoutes()}</Router>;
    }
}

const mapStateToProps = store => {
    return {
        errorCode: store.trainer.errorCode,
        countdown: store.trainer.countdown,
        requireOnboarding:
            store.trainer.userInterfaceConfig.requirePersonalUsername &&
            store.user.initialized &&
            !store.user.personalUsername,
        isTrainerActive: store.trainer.isTrainerActive,
        deviceListError: store.trainer.deviceListError,
        isAuthenticated: !!store.user.token || !store.user.authWithPasswordRequired
    };
};

const mapDispatchToProps = {
    reload: userReload
};

export const PureApp = App;
export default connect(mapStateToProps, mapDispatchToProps)(withLocalization(App));
