export class Player {
    constructor(option = {}) {
        const defaults = {
            channels: 1,
            sampleRate: 8000,
            flushingTime: 1000
        };
        this.option = { ...defaults, ...option };
        this.samples = new Float32Array();
        this.flush = this.flush.bind(this);
        this.interval = setInterval(this.flush, this.option.flushingTime);
        this.createContext();
    }

    createContext() {
        this.audioCtx = new (window.AudioContext || window.webkitAudioContext)();
        this.audioCtx.resume();
        this.startTime = this.audioCtx.currentTime;
    }

    isTypedArray(data) {
        return data.byteLength && data.buffer && data.buffer.constructor === ArrayBuffer;
    }

    feed(data) {
        if (!this.isTypedArray(data)) return;
        const tmp = new Float32Array(this.samples.length + data.length);
        tmp.set(this.samples, 0);
        tmp.set(data, this.samples.length);
        this.samples = tmp;
    }

    destroy() {
        if (this.interval) {
            clearInterval(this.interval);
        }
        this.samples = new Float32Array();
        this.audioCtx.close();
    }

    flush() {
        if (!this.samples.length) return;
        const bufferSource = this.audioCtx.createBufferSource();
        const length = this.samples.length / (this.option.channels || 1);
        const audioBuffer = this.audioCtx.createBuffer(
            this.option.channels,
            length,
            this.option.sampleRate
        );

        for (let channel = 0; channel < this.option.channels; channel++) {
            const audioData = audioBuffer.getChannelData(channel);
            let offset = channel;
            let decrement = 50;
            for (let i = 0; i < length; i++) {
                audioData[i] = this.samples[offset];
                if (i < 50) {
                    audioData[i] *= i / 50;
                }
                if (i >= length - 51) {
                    audioData[i] *= decrement-- / 50;
                }
                offset += this.option.channels;
            }
        }

        if (this.startTime < this.audioCtx.currentTime) {
            this.startTime = this.audioCtx.currentTime;
        }

        bufferSource.buffer = audioBuffer;
        bufferSource.connect(this.audioCtx.destination);
        bufferSource.start(this.startTime);
        this.startTime += audioBuffer.duration;
        this.samples = new Float32Array();
    }
}
