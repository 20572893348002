/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
(function(global, factory) { /* global define, require, module */

    /* AMD */ if (typeof define === 'function' && define.amd)
        define(["protobufjs/minimal"], factory);

    /* CommonJS */ else if (typeof require === 'function' && typeof module === 'object' && module && module.exports)
        module.exports = factory(require("protobufjs/minimal"));

})(this, function($protobuf) {
    "use strict";

    // Common aliases
    var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;
    
    // Exported root namespace
    var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});
    
    $root.voice = (function() {
    
        /**
         * Namespace voice.
         * @exports voice
         * @namespace
         */
        var voice = {};
    
        voice.Auth = (function() {
    
            /**
             * Properties of an Auth.
             * @memberof voice
             * @interface IAuth
             * @property {string|null} [token] Auth token
             */
    
            /**
             * Constructs a new Auth.
             * @memberof voice
             * @classdesc Represents an Auth.
             * @implements IAuth
             * @constructor
             * @param {voice.IAuth=} [properties] Properties to set
             */
            function Auth(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Auth token.
             * @member {string} token
             * @memberof voice.Auth
             * @instance
             */
            Auth.prototype.token = "";
    
            /**
             * Creates a new Auth instance using the specified properties.
             * @function create
             * @memberof voice.Auth
             * @static
             * @param {voice.IAuth=} [properties] Properties to set
             * @returns {voice.Auth} Auth instance
             */
            Auth.create = function create(properties) {
                return new Auth(properties);
            };
    
            /**
             * Encodes the specified Auth message. Does not implicitly {@link voice.Auth.verify|verify} messages.
             * @function encode
             * @memberof voice.Auth
             * @static
             * @param {voice.IAuth} message Auth message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Auth.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.token != null && Object.hasOwnProperty.call(message, "token"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.token);
                return writer;
            };
    
            /**
             * Encodes the specified Auth message, length delimited. Does not implicitly {@link voice.Auth.verify|verify} messages.
             * @function encodeDelimited
             * @memberof voice.Auth
             * @static
             * @param {voice.IAuth} message Auth message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Auth.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an Auth message from the specified reader or buffer.
             * @function decode
             * @memberof voice.Auth
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {voice.Auth} Auth
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Auth.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.voice.Auth();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.token = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an Auth message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof voice.Auth
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {voice.Auth} Auth
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Auth.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an Auth message.
             * @function verify
             * @memberof voice.Auth
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Auth.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.token != null && message.hasOwnProperty("token"))
                    if (!$util.isString(message.token))
                        return "token: string expected";
                return null;
            };
    
            /**
             * Creates an Auth message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof voice.Auth
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {voice.Auth} Auth
             */
            Auth.fromObject = function fromObject(object) {
                if (object instanceof $root.voice.Auth)
                    return object;
                var message = new $root.voice.Auth();
                if (object.token != null)
                    message.token = String(object.token);
                return message;
            };
    
            /**
             * Creates a plain object from an Auth message. Also converts values to other types if specified.
             * @function toObject
             * @memberof voice.Auth
             * @static
             * @param {voice.Auth} message Auth
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Auth.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.token = "";
                if (message.token != null && message.hasOwnProperty("token"))
                    object.token = message.token;
                return object;
            };
    
            /**
             * Converts this Auth to JSON.
             * @function toJSON
             * @memberof voice.Auth
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Auth.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Auth
             * @function getTypeUrl
             * @memberof voice.Auth
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Auth.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/voice.Auth";
            };
    
            return Auth;
        })();
    
        voice.Config = (function() {
    
            /**
             * Properties of a Config.
             * @memberof voice
             * @interface IConfig
             * @property {string|null} [language] Config language
             * @property {string|null} [audioCodec] Config audioCodec
             * @property {string|null} [ttsVoice] Config ttsVoice
             * @property {string|null} [ttsCodec] Config ttsCodec
             */
    
            /**
             * Constructs a new Config.
             * @memberof voice
             * @classdesc Represents a Config.
             * @implements IConfig
             * @constructor
             * @param {voice.IConfig=} [properties] Properties to set
             */
            function Config(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Config language.
             * @member {string} language
             * @memberof voice.Config
             * @instance
             */
            Config.prototype.language = "";
    
            /**
             * Config audioCodec.
             * @member {string} audioCodec
             * @memberof voice.Config
             * @instance
             */
            Config.prototype.audioCodec = "";
    
            /**
             * Config ttsVoice.
             * @member {string} ttsVoice
             * @memberof voice.Config
             * @instance
             */
            Config.prototype.ttsVoice = "";
    
            /**
             * Config ttsCodec.
             * @member {string} ttsCodec
             * @memberof voice.Config
             * @instance
             */
            Config.prototype.ttsCodec = "";
    
            /**
             * Creates a new Config instance using the specified properties.
             * @function create
             * @memberof voice.Config
             * @static
             * @param {voice.IConfig=} [properties] Properties to set
             * @returns {voice.Config} Config instance
             */
            Config.create = function create(properties) {
                return new Config(properties);
            };
    
            /**
             * Encodes the specified Config message. Does not implicitly {@link voice.Config.verify|verify} messages.
             * @function encode
             * @memberof voice.Config
             * @static
             * @param {voice.IConfig} message Config message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Config.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.language != null && Object.hasOwnProperty.call(message, "language"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.language);
                if (message.audioCodec != null && Object.hasOwnProperty.call(message, "audioCodec"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.audioCodec);
                if (message.ttsVoice != null && Object.hasOwnProperty.call(message, "ttsVoice"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.ttsVoice);
                if (message.ttsCodec != null && Object.hasOwnProperty.call(message, "ttsCodec"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.ttsCodec);
                return writer;
            };
    
            /**
             * Encodes the specified Config message, length delimited. Does not implicitly {@link voice.Config.verify|verify} messages.
             * @function encodeDelimited
             * @memberof voice.Config
             * @static
             * @param {voice.IConfig} message Config message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Config.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Config message from the specified reader or buffer.
             * @function decode
             * @memberof voice.Config
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {voice.Config} Config
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Config.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.voice.Config();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.language = reader.string();
                            break;
                        }
                    case 2: {
                            message.audioCodec = reader.string();
                            break;
                        }
                    case 3: {
                            message.ttsVoice = reader.string();
                            break;
                        }
                    case 4: {
                            message.ttsCodec = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Config message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof voice.Config
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {voice.Config} Config
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Config.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Config message.
             * @function verify
             * @memberof voice.Config
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Config.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.language != null && message.hasOwnProperty("language"))
                    if (!$util.isString(message.language))
                        return "language: string expected";
                if (message.audioCodec != null && message.hasOwnProperty("audioCodec"))
                    if (!$util.isString(message.audioCodec))
                        return "audioCodec: string expected";
                if (message.ttsVoice != null && message.hasOwnProperty("ttsVoice"))
                    if (!$util.isString(message.ttsVoice))
                        return "ttsVoice: string expected";
                if (message.ttsCodec != null && message.hasOwnProperty("ttsCodec"))
                    if (!$util.isString(message.ttsCodec))
                        return "ttsCodec: string expected";
                return null;
            };
    
            /**
             * Creates a Config message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof voice.Config
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {voice.Config} Config
             */
            Config.fromObject = function fromObject(object) {
                if (object instanceof $root.voice.Config)
                    return object;
                var message = new $root.voice.Config();
                if (object.language != null)
                    message.language = String(object.language);
                if (object.audioCodec != null)
                    message.audioCodec = String(object.audioCodec);
                if (object.ttsVoice != null)
                    message.ttsVoice = String(object.ttsVoice);
                if (object.ttsCodec != null)
                    message.ttsCodec = String(object.ttsCodec);
                return message;
            };
    
            /**
             * Creates a plain object from a Config message. Also converts values to other types if specified.
             * @function toObject
             * @memberof voice.Config
             * @static
             * @param {voice.Config} message Config
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Config.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.language = "";
                    object.audioCodec = "";
                    object.ttsVoice = "";
                    object.ttsCodec = "";
                }
                if (message.language != null && message.hasOwnProperty("language"))
                    object.language = message.language;
                if (message.audioCodec != null && message.hasOwnProperty("audioCodec"))
                    object.audioCodec = message.audioCodec;
                if (message.ttsVoice != null && message.hasOwnProperty("ttsVoice"))
                    object.ttsVoice = message.ttsVoice;
                if (message.ttsCodec != null && message.hasOwnProperty("ttsCodec"))
                    object.ttsCodec = message.ttsCodec;
                return object;
            };
    
            /**
             * Converts this Config to JSON.
             * @function toJSON
             * @memberof voice.Config
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Config.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Config
             * @function getTypeUrl
             * @memberof voice.Config
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Config.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/voice.Config";
            };
    
            return Config;
        })();
    
        voice.Audio = (function() {
    
            /**
             * Properties of an Audio.
             * @memberof voice
             * @interface IAudio
             * @property {Uint8Array|null} [audio] Audio audio
             * @property {number|null} [timestamp] Audio timestamp
             * @property {boolean|null} [newStream] Audio newStream
             */
    
            /**
             * Constructs a new Audio.
             * @memberof voice
             * @classdesc Represents an Audio.
             * @implements IAudio
             * @constructor
             * @param {voice.IAudio=} [properties] Properties to set
             */
            function Audio(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Audio audio.
             * @member {Uint8Array} audio
             * @memberof voice.Audio
             * @instance
             */
            Audio.prototype.audio = $util.newBuffer([]);
    
            /**
             * Audio timestamp.
             * @member {number} timestamp
             * @memberof voice.Audio
             * @instance
             */
            Audio.prototype.timestamp = 0;
    
            /**
             * Audio newStream.
             * @member {boolean} newStream
             * @memberof voice.Audio
             * @instance
             */
            Audio.prototype.newStream = false;
    
            /**
             * Creates a new Audio instance using the specified properties.
             * @function create
             * @memberof voice.Audio
             * @static
             * @param {voice.IAudio=} [properties] Properties to set
             * @returns {voice.Audio} Audio instance
             */
            Audio.create = function create(properties) {
                return new Audio(properties);
            };
    
            /**
             * Encodes the specified Audio message. Does not implicitly {@link voice.Audio.verify|verify} messages.
             * @function encode
             * @memberof voice.Audio
             * @static
             * @param {voice.IAudio} message Audio message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Audio.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.audio != null && Object.hasOwnProperty.call(message, "audio"))
                    writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.audio);
                if (message.timestamp != null && Object.hasOwnProperty.call(message, "timestamp"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.timestamp);
                if (message.newStream != null && Object.hasOwnProperty.call(message, "newStream"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.newStream);
                return writer;
            };
    
            /**
             * Encodes the specified Audio message, length delimited. Does not implicitly {@link voice.Audio.verify|verify} messages.
             * @function encodeDelimited
             * @memberof voice.Audio
             * @static
             * @param {voice.IAudio} message Audio message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Audio.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an Audio message from the specified reader or buffer.
             * @function decode
             * @memberof voice.Audio
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {voice.Audio} Audio
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Audio.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.voice.Audio();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.audio = reader.bytes();
                            break;
                        }
                    case 2: {
                            message.timestamp = reader.uint32();
                            break;
                        }
                    case 3: {
                            message.newStream = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an Audio message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof voice.Audio
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {voice.Audio} Audio
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Audio.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an Audio message.
             * @function verify
             * @memberof voice.Audio
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Audio.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.audio != null && message.hasOwnProperty("audio"))
                    if (!(message.audio && typeof message.audio.length === "number" || $util.isString(message.audio)))
                        return "audio: buffer expected";
                if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                    if (!$util.isInteger(message.timestamp))
                        return "timestamp: integer expected";
                if (message.newStream != null && message.hasOwnProperty("newStream"))
                    if (typeof message.newStream !== "boolean")
                        return "newStream: boolean expected";
                return null;
            };
    
            /**
             * Creates an Audio message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof voice.Audio
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {voice.Audio} Audio
             */
            Audio.fromObject = function fromObject(object) {
                if (object instanceof $root.voice.Audio)
                    return object;
                var message = new $root.voice.Audio();
                if (object.audio != null)
                    if (typeof object.audio === "string")
                        $util.base64.decode(object.audio, message.audio = $util.newBuffer($util.base64.length(object.audio)), 0);
                    else if (object.audio.length >= 0)
                        message.audio = object.audio;
                if (object.timestamp != null)
                    message.timestamp = object.timestamp >>> 0;
                if (object.newStream != null)
                    message.newStream = Boolean(object.newStream);
                return message;
            };
    
            /**
             * Creates a plain object from an Audio message. Also converts values to other types if specified.
             * @function toObject
             * @memberof voice.Audio
             * @static
             * @param {voice.Audio} message Audio
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Audio.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if (options.bytes === String)
                        object.audio = "";
                    else {
                        object.audio = [];
                        if (options.bytes !== Array)
                            object.audio = $util.newBuffer(object.audio);
                    }
                    object.timestamp = 0;
                    object.newStream = false;
                }
                if (message.audio != null && message.hasOwnProperty("audio"))
                    object.audio = options.bytes === String ? $util.base64.encode(message.audio, 0, message.audio.length) : options.bytes === Array ? Array.prototype.slice.call(message.audio) : message.audio;
                if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                    object.timestamp = message.timestamp;
                if (message.newStream != null && message.hasOwnProperty("newStream"))
                    object.newStream = message.newStream;
                return object;
            };
    
            /**
             * Converts this Audio to JSON.
             * @function toJSON
             * @memberof voice.Audio
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Audio.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Audio
             * @function getTypeUrl
             * @memberof voice.Audio
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Audio.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/voice.Audio";
            };
    
            return Audio;
        })();
    
        voice.Text = (function() {
    
            /**
             * Properties of a Text.
             * @memberof voice
             * @interface IText
             * @property {string|null} [text] Text text
             * @property {number|null} [timestamp] Text timestamp
             */
    
            /**
             * Constructs a new Text.
             * @memberof voice
             * @classdesc Represents a Text.
             * @implements IText
             * @constructor
             * @param {voice.IText=} [properties] Properties to set
             */
            function Text(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Text text.
             * @member {string} text
             * @memberof voice.Text
             * @instance
             */
            Text.prototype.text = "";
    
            /**
             * Text timestamp.
             * @member {number} timestamp
             * @memberof voice.Text
             * @instance
             */
            Text.prototype.timestamp = 0;
    
            /**
             * Creates a new Text instance using the specified properties.
             * @function create
             * @memberof voice.Text
             * @static
             * @param {voice.IText=} [properties] Properties to set
             * @returns {voice.Text} Text instance
             */
            Text.create = function create(properties) {
                return new Text(properties);
            };
    
            /**
             * Encodes the specified Text message. Does not implicitly {@link voice.Text.verify|verify} messages.
             * @function encode
             * @memberof voice.Text
             * @static
             * @param {voice.IText} message Text message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Text.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.text != null && Object.hasOwnProperty.call(message, "text"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.text);
                if (message.timestamp != null && Object.hasOwnProperty.call(message, "timestamp"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.timestamp);
                return writer;
            };
    
            /**
             * Encodes the specified Text message, length delimited. Does not implicitly {@link voice.Text.verify|verify} messages.
             * @function encodeDelimited
             * @memberof voice.Text
             * @static
             * @param {voice.IText} message Text message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Text.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Text message from the specified reader or buffer.
             * @function decode
             * @memberof voice.Text
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {voice.Text} Text
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Text.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.voice.Text();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.text = reader.string();
                            break;
                        }
                    case 2: {
                            message.timestamp = reader.uint32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Text message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof voice.Text
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {voice.Text} Text
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Text.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Text message.
             * @function verify
             * @memberof voice.Text
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Text.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.text != null && message.hasOwnProperty("text"))
                    if (!$util.isString(message.text))
                        return "text: string expected";
                if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                    if (!$util.isInteger(message.timestamp))
                        return "timestamp: integer expected";
                return null;
            };
    
            /**
             * Creates a Text message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof voice.Text
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {voice.Text} Text
             */
            Text.fromObject = function fromObject(object) {
                if (object instanceof $root.voice.Text)
                    return object;
                var message = new $root.voice.Text();
                if (object.text != null)
                    message.text = String(object.text);
                if (object.timestamp != null)
                    message.timestamp = object.timestamp >>> 0;
                return message;
            };
    
            /**
             * Creates a plain object from a Text message. Also converts values to other types if specified.
             * @function toObject
             * @memberof voice.Text
             * @static
             * @param {voice.Text} message Text
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Text.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.text = "";
                    object.timestamp = 0;
                }
                if (message.text != null && message.hasOwnProperty("text"))
                    object.text = message.text;
                if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                    object.timestamp = message.timestamp;
                return object;
            };
    
            /**
             * Converts this Text to JSON.
             * @function toJSON
             * @memberof voice.Text
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Text.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Text
             * @function getTypeUrl
             * @memberof voice.Text
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Text.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/voice.Text";
            };
    
            return Text;
        })();
    
        voice.Request = (function() {
    
            /**
             * Properties of a Request.
             * @memberof voice
             * @interface IRequest
             * @property {voice.IConfig|null} [config] Request config
             * @property {voice.IText|null} [input] Request input
             * @property {voice.IAudio|null} [audio] Request audio
             */
    
            /**
             * Constructs a new Request.
             * @memberof voice
             * @classdesc Represents a Request.
             * @implements IRequest
             * @constructor
             * @param {voice.IRequest=} [properties] Properties to set
             */
            function Request(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Request config.
             * @member {voice.IConfig|null|undefined} config
             * @memberof voice.Request
             * @instance
             */
            Request.prototype.config = null;
    
            /**
             * Request input.
             * @member {voice.IText|null|undefined} input
             * @memberof voice.Request
             * @instance
             */
            Request.prototype.input = null;
    
            /**
             * Request audio.
             * @member {voice.IAudio|null|undefined} audio
             * @memberof voice.Request
             * @instance
             */
            Request.prototype.audio = null;
    
            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;
    
            /**
             * Request request.
             * @member {"config"|"input"|"audio"|undefined} request
             * @memberof voice.Request
             * @instance
             */
            Object.defineProperty(Request.prototype, "request", {
                get: $util.oneOfGetter($oneOfFields = ["config", "input", "audio"]),
                set: $util.oneOfSetter($oneOfFields)
            });
    
            /**
             * Creates a new Request instance using the specified properties.
             * @function create
             * @memberof voice.Request
             * @static
             * @param {voice.IRequest=} [properties] Properties to set
             * @returns {voice.Request} Request instance
             */
            Request.create = function create(properties) {
                return new Request(properties);
            };
    
            /**
             * Encodes the specified Request message. Does not implicitly {@link voice.Request.verify|verify} messages.
             * @function encode
             * @memberof voice.Request
             * @static
             * @param {voice.IRequest} message Request message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Request.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.config != null && Object.hasOwnProperty.call(message, "config"))
                    $root.voice.Config.encode(message.config, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.input != null && Object.hasOwnProperty.call(message, "input"))
                    $root.voice.Text.encode(message.input, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.audio != null && Object.hasOwnProperty.call(message, "audio"))
                    $root.voice.Audio.encode(message.audio, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified Request message, length delimited. Does not implicitly {@link voice.Request.verify|verify} messages.
             * @function encodeDelimited
             * @memberof voice.Request
             * @static
             * @param {voice.IRequest} message Request message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Request.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Request message from the specified reader or buffer.
             * @function decode
             * @memberof voice.Request
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {voice.Request} Request
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Request.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.voice.Request();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.config = $root.voice.Config.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.input = $root.voice.Text.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.audio = $root.voice.Audio.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Request message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof voice.Request
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {voice.Request} Request
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Request.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Request message.
             * @function verify
             * @memberof voice.Request
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Request.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.config != null && message.hasOwnProperty("config")) {
                    properties.request = 1;
                    {
                        var error = $root.voice.Config.verify(message.config);
                        if (error)
                            return "config." + error;
                    }
                }
                if (message.input != null && message.hasOwnProperty("input")) {
                    if (properties.request === 1)
                        return "request: multiple values";
                    properties.request = 1;
                    {
                        var error = $root.voice.Text.verify(message.input);
                        if (error)
                            return "input." + error;
                    }
                }
                if (message.audio != null && message.hasOwnProperty("audio")) {
                    if (properties.request === 1)
                        return "request: multiple values";
                    properties.request = 1;
                    {
                        var error = $root.voice.Audio.verify(message.audio);
                        if (error)
                            return "audio." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a Request message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof voice.Request
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {voice.Request} Request
             */
            Request.fromObject = function fromObject(object) {
                if (object instanceof $root.voice.Request)
                    return object;
                var message = new $root.voice.Request();
                if (object.config != null) {
                    if (typeof object.config !== "object")
                        throw TypeError(".voice.Request.config: object expected");
                    message.config = $root.voice.Config.fromObject(object.config);
                }
                if (object.input != null) {
                    if (typeof object.input !== "object")
                        throw TypeError(".voice.Request.input: object expected");
                    message.input = $root.voice.Text.fromObject(object.input);
                }
                if (object.audio != null) {
                    if (typeof object.audio !== "object")
                        throw TypeError(".voice.Request.audio: object expected");
                    message.audio = $root.voice.Audio.fromObject(object.audio);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a Request message. Also converts values to other types if specified.
             * @function toObject
             * @memberof voice.Request
             * @static
             * @param {voice.Request} message Request
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Request.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (message.config != null && message.hasOwnProperty("config")) {
                    object.config = $root.voice.Config.toObject(message.config, options);
                    if (options.oneofs)
                        object.request = "config";
                }
                if (message.input != null && message.hasOwnProperty("input")) {
                    object.input = $root.voice.Text.toObject(message.input, options);
                    if (options.oneofs)
                        object.request = "input";
                }
                if (message.audio != null && message.hasOwnProperty("audio")) {
                    object.audio = $root.voice.Audio.toObject(message.audio, options);
                    if (options.oneofs)
                        object.request = "audio";
                }
                return object;
            };
    
            /**
             * Converts this Request to JSON.
             * @function toJSON
             * @memberof voice.Request
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Request.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Request
             * @function getTypeUrl
             * @memberof voice.Request
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/voice.Request";
            };
    
            return Request;
        })();
    
        voice.Response = (function() {
    
            /**
             * Properties of a Response.
             * @memberof voice
             * @interface IResponse
             * @property {voice.IText|null} [transcript] Response transcript
             * @property {voice.IText|null} [reply] Response reply
             * @property {voice.IAudio|null} [audio] Response audio
             */
    
            /**
             * Constructs a new Response.
             * @memberof voice
             * @classdesc Represents a Response.
             * @implements IResponse
             * @constructor
             * @param {voice.IResponse=} [properties] Properties to set
             */
            function Response(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Response transcript.
             * @member {voice.IText|null|undefined} transcript
             * @memberof voice.Response
             * @instance
             */
            Response.prototype.transcript = null;
    
            /**
             * Response reply.
             * @member {voice.IText|null|undefined} reply
             * @memberof voice.Response
             * @instance
             */
            Response.prototype.reply = null;
    
            /**
             * Response audio.
             * @member {voice.IAudio|null|undefined} audio
             * @memberof voice.Response
             * @instance
             */
            Response.prototype.audio = null;
    
            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;
    
            /**
             * Response response.
             * @member {"transcript"|"reply"|"audio"|undefined} response
             * @memberof voice.Response
             * @instance
             */
            Object.defineProperty(Response.prototype, "response", {
                get: $util.oneOfGetter($oneOfFields = ["transcript", "reply", "audio"]),
                set: $util.oneOfSetter($oneOfFields)
            });
    
            /**
             * Creates a new Response instance using the specified properties.
             * @function create
             * @memberof voice.Response
             * @static
             * @param {voice.IResponse=} [properties] Properties to set
             * @returns {voice.Response} Response instance
             */
            Response.create = function create(properties) {
                return new Response(properties);
            };
    
            /**
             * Encodes the specified Response message. Does not implicitly {@link voice.Response.verify|verify} messages.
             * @function encode
             * @memberof voice.Response
             * @static
             * @param {voice.IResponse} message Response message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Response.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.transcript != null && Object.hasOwnProperty.call(message, "transcript"))
                    $root.voice.Text.encode(message.transcript, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.reply != null && Object.hasOwnProperty.call(message, "reply"))
                    $root.voice.Text.encode(message.reply, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.audio != null && Object.hasOwnProperty.call(message, "audio"))
                    $root.voice.Audio.encode(message.audio, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified Response message, length delimited. Does not implicitly {@link voice.Response.verify|verify} messages.
             * @function encodeDelimited
             * @memberof voice.Response
             * @static
             * @param {voice.IResponse} message Response message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Response.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Response message from the specified reader or buffer.
             * @function decode
             * @memberof voice.Response
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {voice.Response} Response
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Response.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.voice.Response();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.transcript = $root.voice.Text.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.reply = $root.voice.Text.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.audio = $root.voice.Audio.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Response message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof voice.Response
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {voice.Response} Response
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Response.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Response message.
             * @function verify
             * @memberof voice.Response
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Response.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.transcript != null && message.hasOwnProperty("transcript")) {
                    properties.response = 1;
                    {
                        var error = $root.voice.Text.verify(message.transcript);
                        if (error)
                            return "transcript." + error;
                    }
                }
                if (message.reply != null && message.hasOwnProperty("reply")) {
                    if (properties.response === 1)
                        return "response: multiple values";
                    properties.response = 1;
                    {
                        var error = $root.voice.Text.verify(message.reply);
                        if (error)
                            return "reply." + error;
                    }
                }
                if (message.audio != null && message.hasOwnProperty("audio")) {
                    if (properties.response === 1)
                        return "response: multiple values";
                    properties.response = 1;
                    {
                        var error = $root.voice.Audio.verify(message.audio);
                        if (error)
                            return "audio." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a Response message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof voice.Response
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {voice.Response} Response
             */
            Response.fromObject = function fromObject(object) {
                if (object instanceof $root.voice.Response)
                    return object;
                var message = new $root.voice.Response();
                if (object.transcript != null) {
                    if (typeof object.transcript !== "object")
                        throw TypeError(".voice.Response.transcript: object expected");
                    message.transcript = $root.voice.Text.fromObject(object.transcript);
                }
                if (object.reply != null) {
                    if (typeof object.reply !== "object")
                        throw TypeError(".voice.Response.reply: object expected");
                    message.reply = $root.voice.Text.fromObject(object.reply);
                }
                if (object.audio != null) {
                    if (typeof object.audio !== "object")
                        throw TypeError(".voice.Response.audio: object expected");
                    message.audio = $root.voice.Audio.fromObject(object.audio);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a Response message. Also converts values to other types if specified.
             * @function toObject
             * @memberof voice.Response
             * @static
             * @param {voice.Response} message Response
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Response.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (message.transcript != null && message.hasOwnProperty("transcript")) {
                    object.transcript = $root.voice.Text.toObject(message.transcript, options);
                    if (options.oneofs)
                        object.response = "transcript";
                }
                if (message.reply != null && message.hasOwnProperty("reply")) {
                    object.reply = $root.voice.Text.toObject(message.reply, options);
                    if (options.oneofs)
                        object.response = "reply";
                }
                if (message.audio != null && message.hasOwnProperty("audio")) {
                    object.audio = $root.voice.Audio.toObject(message.audio, options);
                    if (options.oneofs)
                        object.response = "audio";
                }
                return object;
            };
    
            /**
             * Converts this Response to JSON.
             * @function toJSON
             * @memberof voice.Response
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Response.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Response
             * @function getTypeUrl
             * @memberof voice.Response
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/voice.Response";
            };
    
            return Response;
        })();
    
        voice.BotContext = (function() {
    
            /**
             * Properties of a BotContext.
             * @memberof voice
             * @interface IBotContext
             * @property {Object.<string,string>|null} [properties] BotContext properties
             */
    
            /**
             * Constructs a new BotContext.
             * @memberof voice
             * @classdesc Represents a BotContext.
             * @implements IBotContext
             * @constructor
             * @param {voice.IBotContext=} [properties] Properties to set
             */
            function BotContext(properties) {
                this.properties = {};
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * BotContext properties.
             * @member {Object.<string,string>} properties
             * @memberof voice.BotContext
             * @instance
             */
            BotContext.prototype.properties = $util.emptyObject;
    
            /**
             * Creates a new BotContext instance using the specified properties.
             * @function create
             * @memberof voice.BotContext
             * @static
             * @param {voice.IBotContext=} [properties] Properties to set
             * @returns {voice.BotContext} BotContext instance
             */
            BotContext.create = function create(properties) {
                return new BotContext(properties);
            };
    
            /**
             * Encodes the specified BotContext message. Does not implicitly {@link voice.BotContext.verify|verify} messages.
             * @function encode
             * @memberof voice.BotContext
             * @static
             * @param {voice.IBotContext} message BotContext message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BotContext.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.properties != null && Object.hasOwnProperty.call(message, "properties"))
                    for (var keys = Object.keys(message.properties), i = 0; i < keys.length; ++i)
                        writer.uint32(/* id 1, wireType 2 =*/10).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.properties[keys[i]]).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified BotContext message, length delimited. Does not implicitly {@link voice.BotContext.verify|verify} messages.
             * @function encodeDelimited
             * @memberof voice.BotContext
             * @static
             * @param {voice.IBotContext} message BotContext message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BotContext.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a BotContext message from the specified reader or buffer.
             * @function decode
             * @memberof voice.BotContext
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {voice.BotContext} BotContext
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BotContext.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.voice.BotContext(), key, value;
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (message.properties === $util.emptyObject)
                                message.properties = {};
                            var end2 = reader.uint32() + reader.pos;
                            key = "";
                            value = "";
                            while (reader.pos < end2) {
                                var tag2 = reader.uint32();
                                switch (tag2 >>> 3) {
                                case 1:
                                    key = reader.string();
                                    break;
                                case 2:
                                    value = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag2 & 7);
                                    break;
                                }
                            }
                            message.properties[key] = value;
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a BotContext message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof voice.BotContext
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {voice.BotContext} BotContext
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BotContext.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a BotContext message.
             * @function verify
             * @memberof voice.BotContext
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BotContext.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.properties != null && message.hasOwnProperty("properties")) {
                    if (!$util.isObject(message.properties))
                        return "properties: object expected";
                    var key = Object.keys(message.properties);
                    for (var i = 0; i < key.length; ++i)
                        if (!$util.isString(message.properties[key[i]]))
                            return "properties: string{k:string} expected";
                }
                return null;
            };
    
            /**
             * Creates a BotContext message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof voice.BotContext
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {voice.BotContext} BotContext
             */
            BotContext.fromObject = function fromObject(object) {
                if (object instanceof $root.voice.BotContext)
                    return object;
                var message = new $root.voice.BotContext();
                if (object.properties) {
                    if (typeof object.properties !== "object")
                        throw TypeError(".voice.BotContext.properties: object expected");
                    message.properties = {};
                    for (var keys = Object.keys(object.properties), i = 0; i < keys.length; ++i)
                        message.properties[keys[i]] = String(object.properties[keys[i]]);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a BotContext message. Also converts values to other types if specified.
             * @function toObject
             * @memberof voice.BotContext
             * @static
             * @param {voice.BotContext} message BotContext
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BotContext.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.objects || options.defaults)
                    object.properties = {};
                var keys2;
                if (message.properties && (keys2 = Object.keys(message.properties)).length) {
                    object.properties = {};
                    for (var j = 0; j < keys2.length; ++j)
                        object.properties[keys2[j]] = message.properties[keys2[j]];
                }
                return object;
            };
    
            /**
             * Converts this BotContext to JSON.
             * @function toJSON
             * @memberof voice.BotContext
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BotContext.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for BotContext
             * @function getTypeUrl
             * @memberof voice.BotContext
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BotContext.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/voice.BotContext";
            };
    
            return BotContext;
        })();
    
        voice.ConversationEntry = (function() {
    
            /**
             * Properties of a ConversationEntry.
             * @memberof voice
             * @interface IConversationEntry
             * @property {string|null} [role] ConversationEntry role
             * @property {string|null} [content] ConversationEntry content
             * @property {number|null} [timestamp] ConversationEntry timestamp
             */
    
            /**
             * Constructs a new ConversationEntry.
             * @memberof voice
             * @classdesc Represents a ConversationEntry.
             * @implements IConversationEntry
             * @constructor
             * @param {voice.IConversationEntry=} [properties] Properties to set
             */
            function ConversationEntry(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ConversationEntry role.
             * @member {string} role
             * @memberof voice.ConversationEntry
             * @instance
             */
            ConversationEntry.prototype.role = "";
    
            /**
             * ConversationEntry content.
             * @member {string} content
             * @memberof voice.ConversationEntry
             * @instance
             */
            ConversationEntry.prototype.content = "";
    
            /**
             * ConversationEntry timestamp.
             * @member {number} timestamp
             * @memberof voice.ConversationEntry
             * @instance
             */
            ConversationEntry.prototype.timestamp = 0;
    
            /**
             * Creates a new ConversationEntry instance using the specified properties.
             * @function create
             * @memberof voice.ConversationEntry
             * @static
             * @param {voice.IConversationEntry=} [properties] Properties to set
             * @returns {voice.ConversationEntry} ConversationEntry instance
             */
            ConversationEntry.create = function create(properties) {
                return new ConversationEntry(properties);
            };
    
            /**
             * Encodes the specified ConversationEntry message. Does not implicitly {@link voice.ConversationEntry.verify|verify} messages.
             * @function encode
             * @memberof voice.ConversationEntry
             * @static
             * @param {voice.IConversationEntry} message ConversationEntry message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ConversationEntry.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.role != null && Object.hasOwnProperty.call(message, "role"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.role);
                if (message.content != null && Object.hasOwnProperty.call(message, "content"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.content);
                if (message.timestamp != null && Object.hasOwnProperty.call(message, "timestamp"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.timestamp);
                return writer;
            };
    
            /**
             * Encodes the specified ConversationEntry message, length delimited. Does not implicitly {@link voice.ConversationEntry.verify|verify} messages.
             * @function encodeDelimited
             * @memberof voice.ConversationEntry
             * @static
             * @param {voice.IConversationEntry} message ConversationEntry message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ConversationEntry.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ConversationEntry message from the specified reader or buffer.
             * @function decode
             * @memberof voice.ConversationEntry
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {voice.ConversationEntry} ConversationEntry
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ConversationEntry.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.voice.ConversationEntry();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.role = reader.string();
                            break;
                        }
                    case 2: {
                            message.content = reader.string();
                            break;
                        }
                    case 3: {
                            message.timestamp = reader.uint32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ConversationEntry message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof voice.ConversationEntry
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {voice.ConversationEntry} ConversationEntry
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ConversationEntry.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ConversationEntry message.
             * @function verify
             * @memberof voice.ConversationEntry
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ConversationEntry.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.role != null && message.hasOwnProperty("role"))
                    if (!$util.isString(message.role))
                        return "role: string expected";
                if (message.content != null && message.hasOwnProperty("content"))
                    if (!$util.isString(message.content))
                        return "content: string expected";
                if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                    if (!$util.isInteger(message.timestamp))
                        return "timestamp: integer expected";
                return null;
            };
    
            /**
             * Creates a ConversationEntry message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof voice.ConversationEntry
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {voice.ConversationEntry} ConversationEntry
             */
            ConversationEntry.fromObject = function fromObject(object) {
                if (object instanceof $root.voice.ConversationEntry)
                    return object;
                var message = new $root.voice.ConversationEntry();
                if (object.role != null)
                    message.role = String(object.role);
                if (object.content != null)
                    message.content = String(object.content);
                if (object.timestamp != null)
                    message.timestamp = object.timestamp >>> 0;
                return message;
            };
    
            /**
             * Creates a plain object from a ConversationEntry message. Also converts values to other types if specified.
             * @function toObject
             * @memberof voice.ConversationEntry
             * @static
             * @param {voice.ConversationEntry} message ConversationEntry
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ConversationEntry.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.role = "";
                    object.content = "";
                    object.timestamp = 0;
                }
                if (message.role != null && message.hasOwnProperty("role"))
                    object.role = message.role;
                if (message.content != null && message.hasOwnProperty("content"))
                    object.content = message.content;
                if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                    object.timestamp = message.timestamp;
                return object;
            };
    
            /**
             * Converts this ConversationEntry to JSON.
             * @function toJSON
             * @memberof voice.ConversationEntry
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ConversationEntry.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for ConversationEntry
             * @function getTypeUrl
             * @memberof voice.ConversationEntry
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ConversationEntry.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/voice.ConversationEntry";
            };
    
            return ConversationEntry;
        })();
    
        voice.Conversation = (function() {
    
            /**
             * Properties of a Conversation.
             * @memberof voice
             * @interface IConversation
             * @property {Array.<voice.IConversationEntry>|null} [messages] Conversation messages
             */
    
            /**
             * Constructs a new Conversation.
             * @memberof voice
             * @classdesc Represents a Conversation.
             * @implements IConversation
             * @constructor
             * @param {voice.IConversation=} [properties] Properties to set
             */
            function Conversation(properties) {
                this.messages = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Conversation messages.
             * @member {Array.<voice.IConversationEntry>} messages
             * @memberof voice.Conversation
             * @instance
             */
            Conversation.prototype.messages = $util.emptyArray;
    
            /**
             * Creates a new Conversation instance using the specified properties.
             * @function create
             * @memberof voice.Conversation
             * @static
             * @param {voice.IConversation=} [properties] Properties to set
             * @returns {voice.Conversation} Conversation instance
             */
            Conversation.create = function create(properties) {
                return new Conversation(properties);
            };
    
            /**
             * Encodes the specified Conversation message. Does not implicitly {@link voice.Conversation.verify|verify} messages.
             * @function encode
             * @memberof voice.Conversation
             * @static
             * @param {voice.IConversation} message Conversation message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Conversation.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.messages != null && message.messages.length)
                    for (var i = 0; i < message.messages.length; ++i)
                        $root.voice.ConversationEntry.encode(message.messages[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified Conversation message, length delimited. Does not implicitly {@link voice.Conversation.verify|verify} messages.
             * @function encodeDelimited
             * @memberof voice.Conversation
             * @static
             * @param {voice.IConversation} message Conversation message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Conversation.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Conversation message from the specified reader or buffer.
             * @function decode
             * @memberof voice.Conversation
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {voice.Conversation} Conversation
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Conversation.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.voice.Conversation();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.messages && message.messages.length))
                                message.messages = [];
                            message.messages.push($root.voice.ConversationEntry.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Conversation message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof voice.Conversation
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {voice.Conversation} Conversation
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Conversation.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Conversation message.
             * @function verify
             * @memberof voice.Conversation
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Conversation.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.messages != null && message.hasOwnProperty("messages")) {
                    if (!Array.isArray(message.messages))
                        return "messages: array expected";
                    for (var i = 0; i < message.messages.length; ++i) {
                        var error = $root.voice.ConversationEntry.verify(message.messages[i]);
                        if (error)
                            return "messages." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a Conversation message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof voice.Conversation
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {voice.Conversation} Conversation
             */
            Conversation.fromObject = function fromObject(object) {
                if (object instanceof $root.voice.Conversation)
                    return object;
                var message = new $root.voice.Conversation();
                if (object.messages) {
                    if (!Array.isArray(object.messages))
                        throw TypeError(".voice.Conversation.messages: array expected");
                    message.messages = [];
                    for (var i = 0; i < object.messages.length; ++i) {
                        if (typeof object.messages[i] !== "object")
                            throw TypeError(".voice.Conversation.messages: object expected");
                        message.messages[i] = $root.voice.ConversationEntry.fromObject(object.messages[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a Conversation message. Also converts values to other types if specified.
             * @function toObject
             * @memberof voice.Conversation
             * @static
             * @param {voice.Conversation} message Conversation
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Conversation.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.messages = [];
                if (message.messages && message.messages.length) {
                    object.messages = [];
                    for (var j = 0; j < message.messages.length; ++j)
                        object.messages[j] = $root.voice.ConversationEntry.toObject(message.messages[j], options);
                }
                return object;
            };
    
            /**
             * Converts this Conversation to JSON.
             * @function toJSON
             * @memberof voice.Conversation
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Conversation.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Conversation
             * @function getTypeUrl
             * @memberof voice.Conversation
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Conversation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/voice.Conversation";
            };
    
            return Conversation;
        })();
    
        voice.Command = (function() {
    
            /**
             * Properties of a Command.
             * @memberof voice
             * @interface ICommand
             * @property {voice.Command.Operation|null} [operation] Command operation
             */
    
            /**
             * Constructs a new Command.
             * @memberof voice
             * @classdesc Represents a Command.
             * @implements ICommand
             * @constructor
             * @param {voice.ICommand=} [properties] Properties to set
             */
            function Command(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Command operation.
             * @member {voice.Command.Operation} operation
             * @memberof voice.Command
             * @instance
             */
            Command.prototype.operation = 0;
    
            /**
             * Creates a new Command instance using the specified properties.
             * @function create
             * @memberof voice.Command
             * @static
             * @param {voice.ICommand=} [properties] Properties to set
             * @returns {voice.Command} Command instance
             */
            Command.create = function create(properties) {
                return new Command(properties);
            };
    
            /**
             * Encodes the specified Command message. Does not implicitly {@link voice.Command.verify|verify} messages.
             * @function encode
             * @memberof voice.Command
             * @static
             * @param {voice.ICommand} message Command message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Command.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.operation != null && Object.hasOwnProperty.call(message, "operation"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.operation);
                return writer;
            };
    
            /**
             * Encodes the specified Command message, length delimited. Does not implicitly {@link voice.Command.verify|verify} messages.
             * @function encodeDelimited
             * @memberof voice.Command
             * @static
             * @param {voice.ICommand} message Command message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Command.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Command message from the specified reader or buffer.
             * @function decode
             * @memberof voice.Command
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {voice.Command} Command
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Command.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.voice.Command();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.operation = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Command message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof voice.Command
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {voice.Command} Command
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Command.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Command message.
             * @function verify
             * @memberof voice.Command
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Command.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.operation != null && message.hasOwnProperty("operation"))
                    switch (message.operation) {
                    default:
                        return "operation: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                return null;
            };
    
            /**
             * Creates a Command message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof voice.Command
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {voice.Command} Command
             */
            Command.fromObject = function fromObject(object) {
                if (object instanceof $root.voice.Command)
                    return object;
                var message = new $root.voice.Command();
                switch (object.operation) {
                default:
                    if (typeof object.operation === "number") {
                        message.operation = object.operation;
                        break;
                    }
                    break;
                case "UNSPECIFIED":
                case 0:
                    message.operation = 0;
                    break;
                case "HANGUP":
                case 1:
                    message.operation = 1;
                    break;
                case "TRANSFER":
                case 2:
                    message.operation = 2;
                    break;
                }
                return message;
            };
    
            /**
             * Creates a plain object from a Command message. Also converts values to other types if specified.
             * @function toObject
             * @memberof voice.Command
             * @static
             * @param {voice.Command} message Command
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Command.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.operation = options.enums === String ? "UNSPECIFIED" : 0;
                if (message.operation != null && message.hasOwnProperty("operation"))
                    object.operation = options.enums === String ? $root.voice.Command.Operation[message.operation] === undefined ? message.operation : $root.voice.Command.Operation[message.operation] : message.operation;
                return object;
            };
    
            /**
             * Converts this Command to JSON.
             * @function toJSON
             * @memberof voice.Command
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Command.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for Command
             * @function getTypeUrl
             * @memberof voice.Command
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Command.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/voice.Command";
            };
    
            /**
             * Operation enum.
             * @name voice.Command.Operation
             * @enum {number}
             * @property {number} UNSPECIFIED=0 UNSPECIFIED value
             * @property {number} HANGUP=1 HANGUP value
             * @property {number} TRANSFER=2 TRANSFER value
             */
            Command.Operation = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "UNSPECIFIED"] = 0;
                values[valuesById[1] = "HANGUP"] = 1;
                values[valuesById[2] = "TRANSFER"] = 2;
                return values;
            })();
    
            return Command;
        })();
    
        voice.BotRequest = (function() {
    
            /**
             * Properties of a BotRequest.
             * @memberof voice
             * @interface IBotRequest
             * @property {string|null} [callId] BotRequest callId
             * @property {string|null} [lang] BotRequest lang
             * @property {voice.IText|null} [input] BotRequest input
             * @property {voice.IBotContext|null} [context] BotRequest context
             * @property {voice.IConversation|null} [conversation] BotRequest conversation
             */
    
            /**
             * Constructs a new BotRequest.
             * @memberof voice
             * @classdesc Represents a BotRequest.
             * @implements IBotRequest
             * @constructor
             * @param {voice.IBotRequest=} [properties] Properties to set
             */
            function BotRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * BotRequest callId.
             * @member {string} callId
             * @memberof voice.BotRequest
             * @instance
             */
            BotRequest.prototype.callId = "";
    
            /**
             * BotRequest lang.
             * @member {string} lang
             * @memberof voice.BotRequest
             * @instance
             */
            BotRequest.prototype.lang = "";
    
            /**
             * BotRequest input.
             * @member {voice.IText|null|undefined} input
             * @memberof voice.BotRequest
             * @instance
             */
            BotRequest.prototype.input = null;
    
            /**
             * BotRequest context.
             * @member {voice.IBotContext|null|undefined} context
             * @memberof voice.BotRequest
             * @instance
             */
            BotRequest.prototype.context = null;
    
            /**
             * BotRequest conversation.
             * @member {voice.IConversation|null|undefined} conversation
             * @memberof voice.BotRequest
             * @instance
             */
            BotRequest.prototype.conversation = null;
    
            /**
             * Creates a new BotRequest instance using the specified properties.
             * @function create
             * @memberof voice.BotRequest
             * @static
             * @param {voice.IBotRequest=} [properties] Properties to set
             * @returns {voice.BotRequest} BotRequest instance
             */
            BotRequest.create = function create(properties) {
                return new BotRequest(properties);
            };
    
            /**
             * Encodes the specified BotRequest message. Does not implicitly {@link voice.BotRequest.verify|verify} messages.
             * @function encode
             * @memberof voice.BotRequest
             * @static
             * @param {voice.IBotRequest} message BotRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BotRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.input != null && Object.hasOwnProperty.call(message, "input"))
                    $root.voice.Text.encode(message.input, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.context != null && Object.hasOwnProperty.call(message, "context"))
                    $root.voice.BotContext.encode(message.context, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.conversation != null && Object.hasOwnProperty.call(message, "conversation"))
                    $root.voice.Conversation.encode(message.conversation, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.callId != null && Object.hasOwnProperty.call(message, "callId"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.callId);
                if (message.lang != null && Object.hasOwnProperty.call(message, "lang"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.lang);
                return writer;
            };
    
            /**
             * Encodes the specified BotRequest message, length delimited. Does not implicitly {@link voice.BotRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof voice.BotRequest
             * @static
             * @param {voice.IBotRequest} message BotRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BotRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a BotRequest message from the specified reader or buffer.
             * @function decode
             * @memberof voice.BotRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {voice.BotRequest} BotRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BotRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.voice.BotRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 4: {
                            message.callId = reader.string();
                            break;
                        }
                    case 5: {
                            message.lang = reader.string();
                            break;
                        }
                    case 1: {
                            message.input = $root.voice.Text.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.context = $root.voice.BotContext.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.conversation = $root.voice.Conversation.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a BotRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof voice.BotRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {voice.BotRequest} BotRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BotRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a BotRequest message.
             * @function verify
             * @memberof voice.BotRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BotRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.callId != null && message.hasOwnProperty("callId"))
                    if (!$util.isString(message.callId))
                        return "callId: string expected";
                if (message.lang != null && message.hasOwnProperty("lang"))
                    if (!$util.isString(message.lang))
                        return "lang: string expected";
                if (message.input != null && message.hasOwnProperty("input")) {
                    var error = $root.voice.Text.verify(message.input);
                    if (error)
                        return "input." + error;
                }
                if (message.context != null && message.hasOwnProperty("context")) {
                    var error = $root.voice.BotContext.verify(message.context);
                    if (error)
                        return "context." + error;
                }
                if (message.conversation != null && message.hasOwnProperty("conversation")) {
                    var error = $root.voice.Conversation.verify(message.conversation);
                    if (error)
                        return "conversation." + error;
                }
                return null;
            };
    
            /**
             * Creates a BotRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof voice.BotRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {voice.BotRequest} BotRequest
             */
            BotRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.voice.BotRequest)
                    return object;
                var message = new $root.voice.BotRequest();
                if (object.callId != null)
                    message.callId = String(object.callId);
                if (object.lang != null)
                    message.lang = String(object.lang);
                if (object.input != null) {
                    if (typeof object.input !== "object")
                        throw TypeError(".voice.BotRequest.input: object expected");
                    message.input = $root.voice.Text.fromObject(object.input);
                }
                if (object.context != null) {
                    if (typeof object.context !== "object")
                        throw TypeError(".voice.BotRequest.context: object expected");
                    message.context = $root.voice.BotContext.fromObject(object.context);
                }
                if (object.conversation != null) {
                    if (typeof object.conversation !== "object")
                        throw TypeError(".voice.BotRequest.conversation: object expected");
                    message.conversation = $root.voice.Conversation.fromObject(object.conversation);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a BotRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof voice.BotRequest
             * @static
             * @param {voice.BotRequest} message BotRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BotRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.input = null;
                    object.context = null;
                    object.conversation = null;
                    object.callId = "";
                    object.lang = "";
                }
                if (message.input != null && message.hasOwnProperty("input"))
                    object.input = $root.voice.Text.toObject(message.input, options);
                if (message.context != null && message.hasOwnProperty("context"))
                    object.context = $root.voice.BotContext.toObject(message.context, options);
                if (message.conversation != null && message.hasOwnProperty("conversation"))
                    object.conversation = $root.voice.Conversation.toObject(message.conversation, options);
                if (message.callId != null && message.hasOwnProperty("callId"))
                    object.callId = message.callId;
                if (message.lang != null && message.hasOwnProperty("lang"))
                    object.lang = message.lang;
                return object;
            };
    
            /**
             * Converts this BotRequest to JSON.
             * @function toJSON
             * @memberof voice.BotRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BotRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for BotRequest
             * @function getTypeUrl
             * @memberof voice.BotRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BotRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/voice.BotRequest";
            };
    
            return BotRequest;
        })();
    
        voice.BotResponse = (function() {
    
            /**
             * Properties of a BotResponse.
             * @memberof voice
             * @interface IBotResponse
             * @property {voice.IText|null} [reply] BotResponse reply
             * @property {voice.IBotContext|null} [context] BotResponse context
             * @property {voice.IConversation|null} [conversation] BotResponse conversation
             * @property {voice.ICommand|null} [command] BotResponse command
             */
    
            /**
             * Constructs a new BotResponse.
             * @memberof voice
             * @classdesc Represents a BotResponse.
             * @implements IBotResponse
             * @constructor
             * @param {voice.IBotResponse=} [properties] Properties to set
             */
            function BotResponse(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * BotResponse reply.
             * @member {voice.IText|null|undefined} reply
             * @memberof voice.BotResponse
             * @instance
             */
            BotResponse.prototype.reply = null;
    
            /**
             * BotResponse context.
             * @member {voice.IBotContext|null|undefined} context
             * @memberof voice.BotResponse
             * @instance
             */
            BotResponse.prototype.context = null;
    
            /**
             * BotResponse conversation.
             * @member {voice.IConversation|null|undefined} conversation
             * @memberof voice.BotResponse
             * @instance
             */
            BotResponse.prototype.conversation = null;
    
            /**
             * BotResponse command.
             * @member {voice.ICommand|null|undefined} command
             * @memberof voice.BotResponse
             * @instance
             */
            BotResponse.prototype.command = null;
    
            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;
    
            /**
             * BotResponse response.
             * @member {"reply"|"context"|"conversation"|"command"|undefined} response
             * @memberof voice.BotResponse
             * @instance
             */
            Object.defineProperty(BotResponse.prototype, "response", {
                get: $util.oneOfGetter($oneOfFields = ["reply", "context", "conversation", "command"]),
                set: $util.oneOfSetter($oneOfFields)
            });
    
            /**
             * Creates a new BotResponse instance using the specified properties.
             * @function create
             * @memberof voice.BotResponse
             * @static
             * @param {voice.IBotResponse=} [properties] Properties to set
             * @returns {voice.BotResponse} BotResponse instance
             */
            BotResponse.create = function create(properties) {
                return new BotResponse(properties);
            };
    
            /**
             * Encodes the specified BotResponse message. Does not implicitly {@link voice.BotResponse.verify|verify} messages.
             * @function encode
             * @memberof voice.BotResponse
             * @static
             * @param {voice.IBotResponse} message BotResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BotResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.reply != null && Object.hasOwnProperty.call(message, "reply"))
                    $root.voice.Text.encode(message.reply, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.context != null && Object.hasOwnProperty.call(message, "context"))
                    $root.voice.BotContext.encode(message.context, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.conversation != null && Object.hasOwnProperty.call(message, "conversation"))
                    $root.voice.Conversation.encode(message.conversation, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.command != null && Object.hasOwnProperty.call(message, "command"))
                    $root.voice.Command.encode(message.command, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified BotResponse message, length delimited. Does not implicitly {@link voice.BotResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof voice.BotResponse
             * @static
             * @param {voice.IBotResponse} message BotResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BotResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a BotResponse message from the specified reader or buffer.
             * @function decode
             * @memberof voice.BotResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {voice.BotResponse} BotResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BotResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.voice.BotResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.reply = $root.voice.Text.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.context = $root.voice.BotContext.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.conversation = $root.voice.Conversation.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.command = $root.voice.Command.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a BotResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof voice.BotResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {voice.BotResponse} BotResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BotResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a BotResponse message.
             * @function verify
             * @memberof voice.BotResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BotResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.reply != null && message.hasOwnProperty("reply")) {
                    properties.response = 1;
                    {
                        var error = $root.voice.Text.verify(message.reply);
                        if (error)
                            return "reply." + error;
                    }
                }
                if (message.context != null && message.hasOwnProperty("context")) {
                    if (properties.response === 1)
                        return "response: multiple values";
                    properties.response = 1;
                    {
                        var error = $root.voice.BotContext.verify(message.context);
                        if (error)
                            return "context." + error;
                    }
                }
                if (message.conversation != null && message.hasOwnProperty("conversation")) {
                    if (properties.response === 1)
                        return "response: multiple values";
                    properties.response = 1;
                    {
                        var error = $root.voice.Conversation.verify(message.conversation);
                        if (error)
                            return "conversation." + error;
                    }
                }
                if (message.command != null && message.hasOwnProperty("command")) {
                    if (properties.response === 1)
                        return "response: multiple values";
                    properties.response = 1;
                    {
                        var error = $root.voice.Command.verify(message.command);
                        if (error)
                            return "command." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a BotResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof voice.BotResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {voice.BotResponse} BotResponse
             */
            BotResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.voice.BotResponse)
                    return object;
                var message = new $root.voice.BotResponse();
                if (object.reply != null) {
                    if (typeof object.reply !== "object")
                        throw TypeError(".voice.BotResponse.reply: object expected");
                    message.reply = $root.voice.Text.fromObject(object.reply);
                }
                if (object.context != null) {
                    if (typeof object.context !== "object")
                        throw TypeError(".voice.BotResponse.context: object expected");
                    message.context = $root.voice.BotContext.fromObject(object.context);
                }
                if (object.conversation != null) {
                    if (typeof object.conversation !== "object")
                        throw TypeError(".voice.BotResponse.conversation: object expected");
                    message.conversation = $root.voice.Conversation.fromObject(object.conversation);
                }
                if (object.command != null) {
                    if (typeof object.command !== "object")
                        throw TypeError(".voice.BotResponse.command: object expected");
                    message.command = $root.voice.Command.fromObject(object.command);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a BotResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof voice.BotResponse
             * @static
             * @param {voice.BotResponse} message BotResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BotResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (message.reply != null && message.hasOwnProperty("reply")) {
                    object.reply = $root.voice.Text.toObject(message.reply, options);
                    if (options.oneofs)
                        object.response = "reply";
                }
                if (message.context != null && message.hasOwnProperty("context")) {
                    object.context = $root.voice.BotContext.toObject(message.context, options);
                    if (options.oneofs)
                        object.response = "context";
                }
                if (message.conversation != null && message.hasOwnProperty("conversation")) {
                    object.conversation = $root.voice.Conversation.toObject(message.conversation, options);
                    if (options.oneofs)
                        object.response = "conversation";
                }
                if (message.command != null && message.hasOwnProperty("command")) {
                    object.command = $root.voice.Command.toObject(message.command, options);
                    if (options.oneofs)
                        object.response = "command";
                }
                return object;
            };
    
            /**
             * Converts this BotResponse to JSON.
             * @function toJSON
             * @memberof voice.BotResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BotResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * Gets the default type url for BotResponse
             * @function getTypeUrl
             * @memberof voice.BotResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BotResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/voice.BotResponse";
            };
    
            return BotResponse;
        })();
    
        return voice;
    })();

    return $root;
});
