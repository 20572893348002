import semver from 'semver';
import {
    find,
    reduce,
    snakeCase,
    camelCase,
    isPlainObject,
    get,
    isFunction,
    compact,
    filter,
    isArray,
    map,
    pick
} from 'lodash';
import { proto } from '../services/ListenerProto';
import { commonLogger } from '../../utils/logger';
import { META_INFO_PROPERTIES } from '../constants';

export const guid = () => {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }
    return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
};

export function objectToCase(fn) {
    return target => {
        if (isArray(target)) {
            return map(target, item => objectToCase(fn)(item));
        }
        if (isPlainObject(target)) {
            return reduce(
                target,
                (acc, value, key) => {
                    acc[fn(key)] = objectToCase(fn)(value);
                    return acc;
                },
                {}
            );
        }

        return target;
    };
}

export function objectToSnakeCase(object) {
    return objectToCase(snakeCase)(object);
}

export function objectToCamelCase(object) {
    return objectToCase(camelCase)(object);
}

export function uint8toInt16Array(bytes) {
    return new Int16Array(bytes.buffer, bytes.byteOffset, bytes.byteLength / 2);
}

export function filterDefaultDevices(devices) {
    const mics = compact([
        find(devices, {
            dataFlow: proto.DataFlow.CAPTURE,
            isDefaultConsole: true
        })
    ]);

    const speakers = compact(
        filter(
            devices,
            device =>
                device.dataFlow === proto.DataFlow.RENDER &&
                !(
                    !device.isDefaultConsole &&
                    device.isDefaultCommunications &&
                    !device.isDefaultMultimedia &&
                    device.description.match(/headset/i) &&
                    device.description.match(/hands-?free/i)
                )
        )
    );

    return { mics, speakers };
}

export function uint8ToSting(bytes) {
    return new TextDecoder('utf-8').decode(bytes);
}

export function getDeviceSampleRate(state) {
    return get(state, 'main.recordingInfo.sampleRateHz');
}

export function callIf(value, args, defaultResult) {
    if (isFunction(value)) {
        return value(args) || defaultResult;
    }

    return value || defaultResult;
}

export function invokeIf(target, path, args, defaultResult) {
    const value = get(target, path);

    return callIf(value, args, defaultResult);
}

export function semverDiff(v1, v2) {
    const a = semver.parse(v1);
    const b = semver.parse(v2);

    for (const key of Object.getOwnPropertyNames(a)) {
        if (key === 'major' || key === 'minor' || key === 'patch') {
            if (a[key] !== b[key]) {
                return key;
            }
        }
    }

    return null;
}

export const now = () => Date.now();

export const safeStringify = (obj, space = 2, replacer = null) => {
    try {
        return JSON.stringify(obj, replacer, space);
    } catch (error) {
        const errorMessage = `Failed to stringify obj with keys ${Object.keys(obj)}`;
        commonLogger.error(errorMessage);
        return errorMessage;
    }
};

export function createError({ name, message, fingerprint, extra, status }) {
    class CustomError extends Error {
        constructor(params) {
            const msg =
                typeof message === 'function'
                    ? message(params) || 'no error description'
                    : message;
            super(msg);

            this.name = name;
            this.status = typeof status === 'function' ? status(params) : status;
            this.fingerprint =
                typeof fingerprint === 'function' ? fingerprint(params) : fingerprint;
            this.extra = typeof extra === 'function' ? extra(params) : extra;

            if (Error.captureStackTrace) {
                Error.captureStackTrace(this, this.constructor);
            }
        }
    }

    Object.defineProperty(CustomError, 'name', { value: name });

    return CustomError;
}

export const withPrefix = prefix => fn => (params = {}) =>
    `${callIf(prefix, params)}${callIf(fn, params)}`;

export function parseId(id) {
    const parsed = Number.parseInt(id, 10);
    return Number.isSafeInteger(parsed) ? parsed : 0;
}

export function filterKeys(items, cb) {
    return reduce(
        items,
        (result, value, key) => {
            if (cb(value, key)) {
                return { ...result, [key]: value };
            }
            return result;
        },
        {}
    );
}

/**
 * Checks if the passed number is smaller than Int32 max number:2147483647
 *
 * If you pass NaN, string or Infinity it will return true
 * @param {Number} x
 * @returns boolean
 */
export function checkForBigerThanInt32(x) {
    if (Number.isNaN(x) || !Number.isFinite(x)) {
        return true;
    }

    return Math.abs(x) >= 2147483647;
}

export function renameKeys(obj, newKeys) {
    const keyValues = Object.keys(obj).map(key => {
        const newKey = newKeys[key] || key;
        return { [newKey]: obj[key] };
    });

    return Object.assign({}, ...keyValues);
}

export function isValidEndpoint(url) {
    const expression = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi;
    const regex = new RegExp(expression);
    return regex.test(url);
}

export function getMetaInfo(startEvent) {
    const metaInfo = pick(startEvent, META_INFO_PROPERTIES);

    for (const key in metaInfo) {
        if (typeof metaInfo[key] !== 'string') {
            metaInfo[key] = JSON.stringify(metaInfo[key]);
        }
    }

    return metaInfo;
}

export const APP_PAUSE_STORAGE_KEY = '_app_paused';

/**
 * Pauses or resumes the app by updating local storage.
 * @param {boolean} shouldPause - If true, marks the app as paused; if false, resumes it.
 */
export function setAppPaused(shouldPause = true) {
    if (shouldPause) {
        localStorage.setItem(APP_PAUSE_STORAGE_KEY, '1'); // Mark app as paused
    } else {
        localStorage.removeItem(APP_PAUSE_STORAGE_KEY); // Resume app (remove pause flag)
    }
}

/**
 * Checks if the app is currently paused.
 * @returns {boolean} True if the app is paused, otherwise false.
 */
export function isAppPaused() {
    return !!localStorage.getItem(APP_PAUSE_STORAGE_KEY);
}

export const AUTH_FAIL_MAX_ATTEMPTS = 5;
export const AUTH_FAIL_STORAGE_KEY = '_auth_fails_count';

/**
 * Checks if the authentication failure limit has been exceeded.
 * @returns {boolean} True if the failure count exceeds the max allowed attempts.
 */
export function hasExceededAuthFailLimit() {
    const failCount = Number(localStorage.getItem(AUTH_FAIL_STORAGE_KEY)) || 0;
    return failCount >= AUTH_FAIL_MAX_ATTEMPTS;
}

/**
 * Sets the authentication failure count in local storage.
 * @param {number} count - The failure count to set.
 */
export function updateAuthFailCount(count) {
    localStorage.setItem(AUTH_FAIL_STORAGE_KEY, String(count));
}

/**
 * Increments the authentication failure count by 1.
 */
export function incrementAuthFailCount() {
    const currentCount = Number(localStorage.getItem(AUTH_FAIL_STORAGE_KEY)) || 0;
    updateAuthFailCount(currentCount + 1);
}

/**
 * Resets the authentication failure count (e.g., after successful login).
 */
export function resetAuthFailCount() {
    localStorage.removeItem(AUTH_FAIL_STORAGE_KEY);
}
