import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

const pulse = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
`;

const TimerWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const RedDot = styled.div`
    width: 12px;
    height: 12px;
    background-color: red;
    border-radius: 50%;
    animation: ${pulse} 1s infinite;
`;

const TimerText = styled.span`
    font-size: 1rem;
`;

export default function TimerRecorder() {
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setSeconds(prev => prev + 1);
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const formatTime = secs => {
        const minutes = Math.floor(secs / 60);
        const remainingSeconds = secs % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    return (
        <TimerWrapper>
            <RedDot />
            <TimerText>{formatTime(seconds)}</TimerText>
        </TimerWrapper>
    );
}
