import { slice } from 'lodash';

import { SET_CALL_ID } from '../actions/actionTypes/debug';

// type State = {
//     callIds: Array<Object>
// };
//
// type Action = {
//     type: string,
//     payload: Object
// };

export const initialState = {
    callIds: []
};

// eslint-disable-next-line default-param-last
const debug = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case SET_CALL_ID: {
            const { callId, timestamp } = payload;

            const newCallIds = [...state.callIds, { callId, timestamp }];

            return { callIds: slice(newCallIds, -10, newCallIds.length) };
        }
        default: {
            return state;
        }
    }
};

export default debug;
