import styled from 'styled-components';
import { colors } from '../../../utils';

export const CallSimulatorTitle = styled.h3`
    text-align: center;
    margin: 0;
    font-weight: normal;
`;

export const CallSimulatorIntro = styled.div`
    padding: 0 12px;
    font-size: 0.9em;
    text-align: center;
`;

export const CallSimulatorNote = styled.div`
    padding-left: 8px;
    font-size: 0.8em;
    color: ${colors.gray.jumbo};
`;
