import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import I2XLogo from '../../components/common/icons/I2XLogo';
import { colors } from '../../utils';
import withLocalization from '../../localization/withLocalization';
import { setAppPaused } from '../../background/utils/general';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 100px;
`;

const Button = styled.button`
    border-radius: 24px;
    border: 0;

    margin-top: 20px;

    padding: 12px 24px;

    font-size: 13px;
    font-weight: bold;

    background-color: ${colors.green.aquamarine};
    color: ${colors.white.basic};

    cursor: pointer;

    outline: none;

    & > * {
        vertical-align: middle;
    }

    & > span {
        margin-left: 6px;
    }
`;

class LaunchScreen extends Component {
    static propTypes = {
        localization: PropTypes.object.isRequired
    };

    componentDidMount() {
        // this prevents a reboot if the application starts successfully
        if (window.loadingTimeout) {
            clearTimeout(window.loadingTimeout);
        }

        // For health check
        window.coachLoaded = Date.now();

        // For check for updates
        this.reloadTimeout = setTimeout(() => {
            document.location.reload();
        }, 60 * 1000); // 1 min
    }

    render() {
        return (
            <Wrapper>
                <I2XLogo color={colors.blue.i2x} />
                <Button
                    type="button"
                    onClick={() => {
                        clearTimeout(this.reloadTimeout);
                        setAppPaused(false);
                        document.location.reload();
                    }}>
                    {this.props.localization.getText('trainer.launch')}
                </Button>
            </Wrapper>
        );
    }
}

export default withLocalization(LaunchScreen);
