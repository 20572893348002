import { proto as vibeProto } from '../../background/services/AsrProto';
import { proto as listenerProto } from '../../background/services/ListenerProto';

export class Recorder {
    constructor(onAudioData, onStop) {
        this.onAudioData = onAudioData;
        this.onStop = onStop;
    }

    start() {
        this.stop();
        if (window.electronAPI) {
            window.electronAPI.startRecording({
                recordSysAudio: false
            });

            if (!this._electronRecorderHooksInited) {
                window.electronAPI.onRecorderChunk(chunk => {
                    const audioChunk = vibeProto.AudioChunkWithMetadata.decode(chunk);

                    if (audioChunk.channel === listenerProto.DataFlow.CAPTURE) {
                        this.onAudioData(audioChunk.audioChunk);
                    }
                });

                window.electronAPI.onRecorderStopped(() => {
                    this.onStop();
                });

                this._electronRecorderHooksInited = true;
            }
        }
    }

    stop() {
        if (window.electronAPI?.stopRecording) {
            window.electronAPI.stopRecording();
        }
    }
}
