import EventEmitter from 'eventemitter3';
import { OpusDecoder } from 'opus-decoder';
import { Player } from './Player';
import { Recorder } from './Recorder';
import root from './voice-bot.proto';

export const VOICE_CHAT_EVENTS = {
    STARTED: 'started',
    STOPPED: 'stopped'
};

export class VoiceChat {
    audioQueue = [];

    constructor(endpoint, jwtToken, options, addMessage) {
        this.endpoint = endpoint;
        this.jwtToken = jwtToken;
        this.addMessage = addMessage;
        this.bus = new EventEmitter();
        this.decoder = new OpusDecoder({ forceStereo: true });
        this.options = {
            lang: 'de',
            voice: '',
            speed: '1',
            style: '',
            vad: '',
            ...options
        };
    }

    setOptions(options) {
        this.options = options;
    }

    on(event, fn) {
        this.bus.on(event, fn);
    }

    start() {
        let voice = '';
        if (this.options.voice) {
            voice += `&voice=${this.options.voice}`;
            if (this.options.speed) {
                voice += `:${this.options.speed}`;
                if (this.options.style) {
                    voice += `:${this.options.style}`;
                }
            }
        }

        let vad = '';
        if (this.options.vad) {
            vad = `&vad=${this.options.vad}`;
        }

        // this.jwtToken

        this.ws = new WebSocket(
            `${this.endpoint}?lang=${this.options.lang}${voice}${vad}&codec=opus`
        );
        this.ws.onmessage = this.handleWebSocketMessage.bind(this);
        this.ws.onopen = () => {
            console.log('WebSocket connection established');

            const authRequest = root.voice.Auth.create({
                token: this.jwtToken
            });
            this.ws.send(root.voice.Auth.encode(authRequest).finish());
        };
        this.ws.onclose = () => console.log('WebSocket connection closed');
        this.ws.onerror = error => console.error('WebSocket error', error);

        this.recorder = new Recorder(
            audioBlob => {
                if (this.ws && this.ws.readyState === WebSocket.OPEN) {
                    const request = root.voice.Request.create({
                        audio: { audio: audioBlob }
                    });
                    this.ws.send(root.voice.Request.encode(request).finish());
                }
            },
            () => {
                this.stop();
            }
        );

        this.recorder.start();
        this.bus.emit(VOICE_CHAT_EVENTS.STARTED);
    }

    handleWebSocketMessage(event) {
        if (!event.data) return;
        event.data
            .arrayBuffer()
            .then(buffer => {
                const response = root.voice.Response.decode(new Uint8Array(buffer));

                if (response.transcript) {
                    this.addMessage({ isUser: true, text: response.transcript.text });
                } else if (response.reply) {
                    this.addMessage({ isUser: false, text: response.reply.text });
                } else if (response.audio) {
                    this.audioQueue.push(response.audio.audio);
                    this.playAudio();
                }
            })
            .catch(console.error);
    }

    stop() {
        this.stopAudio();
        this.recorder?.stop();
        this.recorder = undefined;

        if (this.ws) {
            this.ws.close();
            this.ws = undefined;
        }

        this.bus.emit(VOICE_CHAT_EVENTS.STOPPED);
    }

    playAudio() {
        if (this.audioQueue.length === 0) return;

        const opusFrame = this.audioQueue.shift();
        const { channelData, samplesDecoded, sampleRate } = this.decoder.decodeFrame(
            opusFrame
        );

        if (samplesDecoded > 0) {
            const pcmSamples = channelData[0];
            if (!this.player) {
                this.player = new Player({
                    channels: 1,
                    sampleRate,
                    flushingTime: 1000
                });
            }
            this.player.feed(pcmSamples);
        }
        this.playAudio();
    }

    stopAudio() {
        if (this.player) {
            this.player.destroy();
            this.player = undefined;
            this.decoder.reset().catch(console.error);
        }
        this.audioQueue.length = 0;
    }
}
