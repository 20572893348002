import { connect } from 'react-redux';
import Settings from '../../components/Settings';
import { updateUsername, updateLocale } from '../../store/actions/user';
import { copyToClipboard } from '../../store/actions/trainer';
import { displayTelephonyId, hidePersonalUsername } from '../../store/selectors/trainer';

const mapStateToProps = state => ({
    locale: state.user.locale,
    timer: state.user.timer,
    isCallStarted: state.trainer.isStarted,
    deviceList: state.trainer.deviceList,
    daemonVersion: state.trainer.daemonVersion,
    telephonyId: state.user.telephonyId,
    displayTelephonyId: displayTelephonyId(state),
    hidePersonalUsername: hidePersonalUsername(state),
    callIds: state._debug.callIds,
    personalUsername: state.user.personalUsername,
    pending: state.user.pending,
    saved: state.user.saved,
    errorCode: state.user.errorCode,
    initialized: state.user.initialized
});

export default connect(mapStateToProps, {
    setLocale: updateLocale,
    updateUsernameRequest: updateUsername,
    copyToClipboard
})(Settings);
